import React, { useState } from "react";
import remove from "lodash/remove";

import { StudentPageContext } from "../contexts/studentPageContext";
import { useEventListener } from "../hooks/useEventListener";
import { EVENT_TOPICS } from "../utilities/eventUtils";

const StudentPageContextProvider = ({ children }) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState();
  const [totalPages, setTotalPages] = useState();
  const [students, setStudents] = useState();
  const [student, setStudent] = useState();

  useEventListener(
    EVENT_TOPICS.ON_STUDENT_DELETED,
    ({ deletedStudentId }) => {
      const copiedData = [...students];
      remove(copiedData, (item) => item.id === deletedStudentId);

      setStudents(copiedData);
    },
    [students]
  );

  useEventListener(
    EVENT_TOPICS.ON_STUDENT_UPDATED,
    ({ response }) => {
      setStudent(response);
    },
    [student]
  );

  return (
    <StudentPageContext.Provider
      value={{
        student,
        setStudent,
        students,
        setStudents,
        currentPage,
        setCurrentPage,
        totalPages,
        setTotalPages,
        totalRecords,
        setTotalRecords,
        pageSize,
        setPageSize,
      }}
    >
      {children}
    </StudentPageContext.Provider>
  );
};

export default StudentPageContextProvider;
