import React from "react";
import { Card } from "reactstrap";

import { Block, BlockHead, BlockTitle } from "../../Component";

import { DATE_FORMATS } from "../../../utilities/constants";
import { formatDate } from "../../../utilities/dateTimeUtils";

const EmployeeInfoTrainingBlock = ({ user }) => {
  return (
    <Card className="card-inner">
      <Block>
        <BlockHead>
          <BlockTitle tag="h5">Practical Trainings</BlockTitle>
        </BlockHead>
        {user?.practicalTrainings?.map((education, index) => (
          <React.Fragment key={index}>
            {index > 0 && <div className="nk-divider divider md"></div>}
            <div className="profile-ud-list">
              <div className="profile-ud-item w-45 mr-5">
                <div className="profile-ud wider">
                  <span className="profile-ud-label w-20">
                    Name of Institution
                  </span>
                  <span className="profile-ud-value">
                    {education?.nameOfInstitution}
                  </span>
                </div>
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Qualification</span>
                  <span className="profile-ud-value">
                    {education?.qualificationOrOutcomes}
                  </span>
                </div>
              </div>
              <div className="profile-ud-item w-50">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Duration</span>
                  <span className="profile-ud-value">
                    {formatDate(
                      new Date(education?.startDate),
                      DATE_FORMATS.DATE_REGISTERED
                    )}{" "}
                    -{" "}
                    {education?.endDate
                      ? formatDate(
                          new Date(education?.endDate),
                          DATE_FORMATS.DATE_REGISTERED
                        )
                      : "Present"}
                  </span>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </Block>
    </Card>
  );
};

export default EmployeeInfoTrainingBlock;
