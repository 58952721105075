import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Card } from "reactstrap";

import { BlockBetween, BlockTitle } from "../../Component";
import UpdatableText from "../../shared/UpdatabaleText";
import RichTextEditor from "../../input/rich-text/RichTextEditor";
import RichTextDisplayComponent from "../../input/rich-text/RichTextDisplayComponent";
import BlockUpdateButton from "../../button/BlockUpdateButton";

import newsService from "../../../services/newsService";
import { useBeManualFetcher } from "../../../hooks/useBeManualFetcher";
import { LANGUAGE_KEYS, LANGUAGE_WRAPPERS } from "../../../utilities/constants";
import { formatToJSON } from "../../../utilities/formatUtils";
import { appendToFormData } from "../../../utilities/formDataUtils";
import EventEmitter, { EVENT_TOPICS } from "../../../utilities/eventUtils";

const NewsMainInfoBlock = ({ news }) => {
  const { newsId } = useParams();
  const { register, handleSubmit } = useForm();
  const [onFetch] = useBeManualFetcher();

  const [enContent, setEnContent] = useState(
    formatToJSON(news?.description?.[LANGUAGE_KEYS.EN])
  );
  const [ruContent, setRuContent] = useState(
    formatToJSON(news?.description?.[LANGUAGE_KEYS.RU])
  );
  const [uzContent, setUzContent] = useState(
    formatToJSON(news?.description?.[LANGUAGE_KEYS.UZ])
  );
  const [hasEditClicked, setHasEditClicked] = useState(false);

  function handleOnEditClicked() {
    setHasEditClicked((prev) => !prev);
  }

  async function onFormSubmit(form) {
    const { enTitle, ruTitle, uzTitle } = form;
    const requestBody = {
      title: {
        [LANGUAGE_KEYS.EN]: enTitle,
        [LANGUAGE_KEYS.RU]: ruTitle,
        [LANGUAGE_KEYS.UZ]: uzTitle,
      },
      description: {
        [LANGUAGE_KEYS.EN]: JSON.stringify(enContent),
        [LANGUAGE_KEYS.RU]: JSON.stringify(ruContent),
        [LANGUAGE_KEYS.UZ]: JSON.stringify(uzContent),
      },
    };

    await onFetch({
      action: () => newsService.updateNews(newsId, requestBody),
      onLoad: (response) => {
        EventEmitter.emit(EVENT_TOPICS.ON_NEWS_UPDATED, {
          updatedNews: response,
        });
        setHasEditClicked(false);
      },
      successMessage: "News updated successfully",
    });
  }

  return (
    <Card className="card-inner">
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <BlockBetween className={"align-items-center"}>
          <BlockTitle tag="h5">Main Information</BlockTitle>
          <BlockUpdateButton
            hasEditClicked={hasEditClicked}
            handleOnEditClicked={handleOnEditClicked}
          />
        </BlockBetween>
        <div className="profile-ud-list position-relative">
          <div className="profile-ud-item w-100">
            <UpdatableText
              title="Title"
              defaultValue={news?.title?.[LANGUAGE_KEYS.EN]}
              name={"enTitle"}
              register={register}
              onEditClicked={hasEditClicked}
              isWider
            />
          </div>
          <div className="profile-ud-item w-100">
            <UpdatableText
              title="Заголовок"
              defaultValue={news?.title?.[LANGUAGE_KEYS.RU]}
              name={"ruTitle"}
              register={register}
              onEditClicked={hasEditClicked}
              isWider
            />
          </div>
          <div className="profile-ud-item w-100">
            <UpdatableText
              title="Sarlavha"
              defaultValue={news?.title?.[LANGUAGE_KEYS.UZ]}
              name={"uzTitle"}
              register={register}
              onEditClicked={hasEditClicked}
              isWider
            />
          </div>
        </div>
        <div className="profile-ud-list position-relative mt-3">
          <div className="profile-ud-item w-100">
            <UpdatableText
              title="Content In English"
              defaultValue={<RichTextDisplayComponent value={enContent} />}
              editableValueComponent={
                <RichTextEditor value={enContent} setValue={setEnContent} />
              }
              onEditClicked={hasEditClicked}
            />
          </div>
          <div className="profile-ud-item w-100">
            <UpdatableText
              title="Содержание на Русском языке"
              defaultValue={<RichTextDisplayComponent value={ruContent} />}
              editableValueComponent={
                <RichTextEditor value={ruContent} setValue={setRuContent} />
              }
              onEditClicked={hasEditClicked}
            />
          </div>
          <div className="profile-ud-item w-100">
            <UpdatableText
              title="Kontent O'zbek tilida"
              defaultValue={<RichTextDisplayComponent value={uzContent} />}
              editableValueComponent={
                <RichTextEditor value={uzContent} setValue={setUzContent} />
              }
              onEditClicked={hasEditClicked}
            />
          </div>
        </div>
      </form>
    </Card>
  );
};

export default NewsMainInfoBlock;
