import React from "react";

import {
  DataTableBody,
  DataTableHead,
  DataTableRow,
} from "../../components/Component";
import StudentsPageTableBodyItems from "./StudentsPageTableBodyItems";

const StudentsPageTableBody = ({ users, setUsers }) => {
  const onSelectChange = (e, id) => {
    let newData = users;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].check = e.currentTarget.checked;
    setUsers([...newData]);
  };

  const selectorCheck = (e) => {
    let newData;
    newData = users.map((item) => {
      item.check = e.currentTarget.checked;
      return item;
    });
    setUsers([...newData]);
  };

  return (
    <DataTableBody compact>
      <DataTableHead>
        <DataTableRow className="nk-tb-col-check" noText>
          <div className="custom-control custom-control-sm custom-checkbox notext">
            <input
              type="checkbox"
              className="custom-control-input form-control"
              id="pid-all"
              onChange={(e) => selectorCheck(e)}
            />
            <label className="custom-control-label" htmlFor="pid-all"></label>
          </div>
        </DataTableRow>
        <DataTableRow text="Name" />
        <DataTableRow text="Registered Date" />
        <DataTableRow text="Faculty" />
        <DataTableRow text="Scholarship type" />
        <DataTableRow text="Status" />
        <DataTableRow className="nk-tb-col-tools" noText />
      </DataTableHead>
      {users?.map((item) => {
        return (
          <StudentsPageTableBodyItems
            key={item?.id}
            item={item}
            onSelectChange={onSelectChange}
          />
        );
      })}
    </DataTableBody>
  );
};

export default StudentsPageTableBody;
