import merge from "lodash/merge";
import {
  getCurrentEnvironment,
  ENVIRONMENTS_ENUMS,
} from "../utilities/environmentUtils";

import baseConfig from "./repositoryConfig.json";
import devConfig from "./repositoryConfig.dev.json";
import prodConfig from "./repositoryConfig.prod.json";
import stgConfig from "./repositoryConfig.stg.json";

let environmentOverride;

const currentEnv = getCurrentEnvironment();

switch (currentEnv) {
  case ENVIRONMENTS_ENUMS.DEV:
    environmentOverride = devConfig;
    break;
  case ENVIRONMENTS_ENUMS.STG:
    environmentOverride = stgConfig;
    break;

  case ENVIRONMENTS_ENUMS.PROD:
    environmentOverride = prodConfig;
    break;
  default:
    environmentOverride = {};
    break;
}

merge(baseConfig, environmentOverride);

export const config = { ...baseConfig };
