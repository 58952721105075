export const ENVIRONMENTS_ENUMS = {
  LOCAL: "local",
  PROD: "prod",
  DEV: "dev",
  STG: "stg",
};

const envPatterns = {
  DEV: /.*test.phtu.uz/g,
  STG: /.*test.phtu.uz/g,
  PROD: /.*phtu.uz/g,
};

export const getCurrentEnvironment = () => {
  // const currentHost = window.location.host.toLowerCase();
  const currentHost = process.env.APP_ENVIRONMENT;

  if (envPatterns.PROD.test(currentHost)) {
    return ENVIRONMENTS_ENUMS.PROD;
  }

  if (envPatterns.DEV.test(currentHost)) {
    return ENVIRONMENTS_ENUMS.DEV;
  }

  if (envPatterns.STG.test(currentHost)) {
    return ENVIRONMENTS_ENUMS.STG;
  }

  return ENVIRONMENTS_ENUMS.LOCAL;
};
