import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Icon } from "../Component";
import SpinnerComponent from "../shared/SpinnerComponent";

const Button = ({
  color,
  size,
  className,
  outline,
  disabled,
  iconSecond,
  title,
  iconName,
  iconClass,
  onClick,
  isLoading = false,
  ...props
}) => {
  const buttonClass = classNames({
    btn: true,
    [`btn-${color}`]: !outline,
    [`btn-outline-${color}`]: outline,
    [`btn-${size}`]: size,
    disabled: disabled,
    [`${className}`]: className,
  });

  const { t } = useTranslation();

  return (
    <button
      className={buttonClass}
      onClick={onClick}
      disabled={disabled || isLoading}
      {...props}
    >
      <>
        {iconSecond ? (
          <>
            {title && <span>{t(title)}</span>}
            {isLoading ? (
              <SpinnerComponent height={"100%"} size="sm" className={"ml-1"} />
            ) : (
              iconName && <Icon name={iconName} className={iconClass} />
            )}
          </>
        ) : (
          <>
            {isLoading ? (
              <SpinnerComponent height={"100%"} size="sm" className={"mr-1"} />
            ) : (
              iconName && <Icon name={iconName} className={iconClass} />
            )}
            {title && <span>{t(title)}</span>}
          </>
        )}
      </>
    </button>
  );
};
export default Button;
