import { isUndefined } from "lodash";
import { IMAGE_EXTENSIONS, IMAGE_KEYS } from "./constants";

const imageToFileConvert = (file, fileName) => {
  return new File(
    [file],
    `${fileName}.${
      file?.type?.split("/")?.[1] === "svg+xml"
        ? "svg"
        : file?.type?.split("/")?.[1]
    }`,
    {
      type: file?.type,
    }
  );
};

const getImageDimensions = (file) => {
  let img = new Image();

  const promise = new Promise((resolve) => {
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      resolve({ width, height });
    };
  });

  img.src = file?.preview;

  return promise;
};

const getImageExtension = (file) => {
  return `.${file?.type?.split("/")?.[1]?.toLowerCase()}`;
};

const getURLExtension = (url) => {
  return url?.split(".")?.slice(-1)?.[0];
};

const imageExtensionChecker = (file) => {
  const imageExtension = getImageExtension(file);

  const isExtensionValidationFailed = isUndefined(
    Object.values(IMAGE_EXTENSIONS).find((ext) => ext === imageExtension)
  );

  return { isExtensionValidationFailed, imageExtension };
};

const imageDimensionChecker = async (file, imageType) => {
  let isDimensionValidationFailed, dimensionErrorMessage;
  const dimensions = await getImageDimensions(file);

  switch (imageType) {
    case IMAGE_KEYS.COURIER_IMAGE:
      isDimensionValidationFailed =
        dimensions.width < IMAGE_KEYS.COURIER_IMAGE_MINIMUM_SIZE ||
        dimensions.height < IMAGE_KEYS.COURIER_IMAGE_MINIMUM_SIZE;
      dimensionErrorMessage = "Minimum of 1000x1000 sized image is required";
      break;

    case IMAGE_KEYS.COVER_IMAGE:
      isDimensionValidationFailed =
        dimensions.width !== IMAGE_KEYS.COVER_IMAGE_WIDTH_SIZE ||
        dimensions.height !== IMAGE_KEYS.COVER_IMAGE_HEIGHT_SIZE;

      dimensionErrorMessage = "Exactly 1920x1080 sized image is required";
      break;

    case IMAGE_KEYS.PRODUCT_IMAGE:
      isDimensionValidationFailed =
        dimensions.width < IMAGE_KEYS.PRODUCT_IMAGE_MINIMUM_SIZE ||
        dimensions.height < IMAGE_KEYS.PRODUCT_IMAGE_MINIMUM_SIZE;

      dimensionErrorMessage = "Minimum of 1500x1500 sized image is required";
      break;

    case IMAGE_KEYS.LOGO_IMAGE:
      isDimensionValidationFailed =
        dimensions.width !== IMAGE_KEYS.LOGO_IMAGE_SIZE ||
        dimensions.height !== IMAGE_KEYS.LOGO_IMAGE_SIZE;

      dimensionErrorMessage = "Exactly 500x500 sized image is required";
      break;

    default:
      isDimensionValidationFailed = false;
      break;
  }

  return { isDimensionValidationFailed, dimensionErrorMessage };
};

const imageSizeChecker = (file, imageType) => {
  let isSizeValidationFailed, sizeErrorMessage;

  switch (imageType) {
    case IMAGE_KEYS.LOGO_IMAGE:
      isSizeValidationFailed = file?.size > 200000;
      sizeErrorMessage = "Maximum 200kb sized image is required";
      break;

    case IMAGE_KEYS.COVER_IMAGE ||
      IMAGE_KEYS.PRODUCT_IMAGE ||
      IMAGE_KEYS.COURIER_IMAGE:
      isSizeValidationFailed = file?.size > 512000;
      sizeErrorMessage = "Maximum 500kb sized image is required";
      break;

    default:
      isSizeValidationFailed = false;
      break;
  }

  return { isSizeValidationFailed, sizeErrorMessage };
};

export {
  imageToFileConvert,
  getImageDimensions,
  getImageExtension,
  imageExtensionChecker,
  imageDimensionChecker,
  imageSizeChecker,
  getURLExtension,
};
