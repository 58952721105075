import React, { useEffect, useState } from "react";

import { AuthContext } from "../contexts/authContext";
import {
  authRefreshAccessToken,
  authSignIn,
} from "../repositories/authRepository";
import {
  logout as handleLogout,
  getAccessToken,
  getRefreshToken,
} from "../services/authService";
import { setCookie } from "../utilities/storageUtils";
import { getCurrentUser } from "../repositories/userRepository";

const AuthContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [authTokens, setAuthTokens] = useState(() => ({
    accessToken: getAccessToken() || null,
    refreshToken: getRefreshToken() || null,
  }));

  const logout = () => {
    setAuthTokens({ accessToken: null, refreshToken: null });
    handleLogout();
  };

  const login = async ({ email, password }) => {
    setLoading(true);
    try {
      const { accessToken, refreshToken, user } = await authSignIn({
        email,
        password,
      });
      setAuthTokens({ accessToken, refreshToken });
      setUser(user);
      setCookie("accessToken", accessToken);
      setCookie("refreshToken", refreshToken);
    } catch (err) {
      alert(`Error in logging in: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const getCurUser = async () => {
    try {
      const accessToken = getAccessToken();
      if (accessToken && !user) {
        const user = await getCurrentUser();
        setUser(user);
      }
    } catch (err) {
      logout();
      setUser(null);
    }
  };

  const refreshAccessToken = async () => {
    try {
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        const { accessToken } = await authRefreshAccessToken(refreshToken);
        setAuthTokens((prev) => ({ ...prev, accessToken }));
        setCookie("accessToken", accessToken);
      } else {
        alert("Not authorized");
        return;
      }
    } catch (err) {
      alert(`Error in refreshing access token: ${err.message}`);
      logout();
    }
  };

  useEffect(() => {
    const authTokens = {
      accessToken: getAccessToken(),
      refreshToken: getRefreshToken(),
    };

    if (!authTokens.accessToken && authTokens.refreshToken) {
      refreshAccessToken();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authTokens,
        getCurUser,
        login,
        logout,
        refreshAccessToken,
        loading,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
