import React, { useState } from "react";
import remove from "lodash/remove";

import { NewsPageContext } from "../contexts/newsPageContext";
import { useEventListener } from "../hooks/useEventListener";
import { EVENT_TOPICS } from "../utilities/eventUtils";
import { swapPosition } from "../utilities/sequenceChangeUtils";

const NewsPageContextProvider = ({ children }) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState();
  const [totalPages, setTotalPages] = useState();
  const [news, setNews] = useState();
  const [singleNews, setSingleNews] = useState();

  useEventListener(
    EVENT_TOPICS.ON_NEWS_DELETED,
    ({ deletedNewsId }) => {
      const copiedData = [...news];
      remove(copiedData, (item) => item.id === deletedNewsId);

      setNews(copiedData);
    },
    [news]
  );

  useEventListener(
    EVENT_TOPICS.ON_NEWS_UPDATED,
    ({ updatedNews }) => {
      setSingleNews((prev) => {
        return { ...prev, ...updatedNews };
      });
    },
    [singleNews]
  );

  useEventListener(
    EVENT_TOPICS.ON_NEWS_SEQUENCE_CHANGED,
    ({ result }) => swapPosition(result, news, setNews),
    [news, setNews]
  );

  return (
    <NewsPageContext.Provider
      value={{
        singleNews,
        setSingleNews,
        news,
        setNews,
        currentPage,
        setCurrentPage,
        totalPages,
        setTotalPages,
        totalRecords,
        setTotalRecords,
        pageSize,
        setPageSize,
      }}
    >
      {children}
    </NewsPageContext.Provider>
  );
};

export default NewsPageContextProvider;
