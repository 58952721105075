import { config } from "../configs/repositoryConfig";
import { convertValuesToArray } from "../utilities/objectUtils";
import { throwHttpError } from "../utilities/errorHandlingUtils";
import { getAccessToken, getRefreshToken } from "../services/authService";
import { setCookie } from "../utilities/storageUtils";
import { authRefreshAccessToken } from "./authRepository";

export const DEFAULT_HEADERS = {
  contentType: {
    key: "Content-Type",
    value: "application/json",
  },
};

export default async function backendHttpRequest({
  resourceObject,
  queryString = "",
  body,
  isFormData = false,
  restHeaders,
  parseResponse = JSON.parse,
  url,
  ...rest
}) {
  const { schema, hostname, basePath, headers } = config.backendApiSettings;
  const baseUrl = url ?? `${schema}://${hostname}${basePath.v1}`;
  let accessToken = resourceObject.isPublic ? null : getAccessToken();

  const contentTypeHeader = !isFormData && {
    [DEFAULT_HEADERS.contentType.key]: DEFAULT_HEADERS.contentType.value,
  };
  const authenticationHeader = accessToken && {
    [headers?.authentication?.key]: `Bearer ${accessToken}`,
  };

  const request = {
    method: resourceObject.method,
    headers: {
      ...authenticationHeader,
      ...contentTypeHeader,
      ...restHeaders,
    },
    body: isFormData ? body : JSON.stringify(body),
    ...rest,
  };

  try {
    let response = await fetch(
      `${baseUrl}${resourceObject.endpoint ?? ""}${queryString}`,
      request
    );

    if (response.status === 401) {
      const refreshToken = await getRefreshToken();

      const res = await authRefreshAccessToken(refreshToken);
      accessToken = res.accessToken;
      setCookie("accessToken", accessToken);

      response = await fetch(
        `${baseUrl}${resourceObject.endpoint ?? ""}${queryString}`,
        request
      );
    }

    if (response.ok) {
      const responseText = await response.text();
      if (responseText) {
        const responseData = parseResponse(responseText);
        return responseData;
      }
      return;
    }



    const errorData = await response.json();

    throw errorData;
  } catch (errorData) {
    throw throwHttpError({
      statusCode: errorData?.status ?? 500,
      beErrorCode: errorData?.errorCode ?? 1000,
      message: errorData?.title ?? "Something went wrong",
      errors: errorData?.errors ? convertValuesToArray(errorData.errors) : [],
    });
  }
}
