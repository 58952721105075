import React, { useCallback } from "react";

import { useSearchParams } from "react-router-dom";

import MainPageTableHeader from "../../layout/body/MainPageTableHeader";
import MainPageTableBody from "../../layout/body/MainPageTableBody";
import MainPageHOC from "../../layout/hoc/MainPageHOC";

import { PaginationComponent } from "../../components/Component";
import FetcherComponent from "../../components/shared/FetcherComponent";
import MainPageHeader from "../../components/shared/MainPageHeader";

import newsService from "../../services/newsService";
import { useNewsPageContext } from "../../hooks/useNewsPageContext";
import { useBeManualFetcher } from "../../hooks/useBeManualFetcher";
import NewsPageTableBody from "../../components/newsPage/NewsPageTableBody";

const NewsPage = () => {
  const {
    news,
    setNews,
    currentPage,
    setCurrentPage,
    totalPages,
    setTotalPages,
    totalRecords,
    setTotalRecords,
    setPageSize,
    pageSize,
  } = useNewsPageContext();

  const [searchParams] = useSearchParams();
  const currentPageQueryString = searchParams.get("current-page");
  const pageSizeQueryString = searchParams.get("page-size");

  const fetchNews = useCallback(
    async () =>
      await newsService.getNews({
        currentPage: currentPageQueryString,
        pageSize: pageSizeQueryString,
      }),
    [currentPageQueryString, pageSizeQueryString]
  );

  const handleOnLoadFetchedNews = useCallback(
    (res) => {
      setNews(res?.data);
      setCurrentPage(res?.currentPage);
      setTotalPages(res?.totalPages);
      setTotalRecords(res?.totalRecords);
      setPageSize(res?.pageSize);
    },
    [setNews, setCurrentPage, setTotalPages, setTotalRecords, setPageSize]
  );

  return (
    <MainPageHOC
      headerTitle={"News"}
      pageHeader={
        <MainPageHeader
          totalRecords={totalRecords}
          pageName="News"
          redirectTo={"create"}
          showAddButton={true}
        />
      }
      tableHeader={
        <MainPageTableHeader pageSize={pageSize} setPageSize={setPageSize} />
      }
      tableBody={
        <FetcherComponent action={fetchNews} onLoad={handleOnLoadFetchedNews}>
          <>
            <NewsPageTableBody pageName={"news"} />

            <PaginationComponent
              currentPageNumber={currentPage}
              totalPages={totalPages}
              totalRecords={totalRecords}
              status={pageSizeQueryString}
            />
          </>
        </FetcherComponent>
      }
    />
  );
};
export default NewsPage;
