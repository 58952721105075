import React from "react";
import { Card } from "reactstrap";

import { Block, BlockHead, BlockTitle } from "../../Component";

const EmployeeInfoRefereeBlock = ({ user }) => {
  return (
    <Card className="card-inner">
      <Block>
        <BlockHead>
          <BlockTitle tag="h5">Referees</BlockTitle>
        </BlockHead>
        {user?.referees?.map((referee, index) => (
          <React.Fragment key={index}>
            {index > 0 && <div className="nk-divider divider md"></div>}
            <div className="profile-ud-list">
              <div className="profile-ud-item w-45 mr-5">
                <div className="profile-ud wider">
                  <span className="profile-ud-label w-20">
                    Name and Surname
                  </span>
                  <span className="profile-ud-value">{referee?.fullName}</span>
                </div>
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Organization</span>
                  <span className="profile-ud-value">
                    {referee?.organization}
                  </span>
                </div>
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Position</span>
                  <span className="profile-ud-value">{referee?.position}</span>
                </div>
              </div>
              <div className="profile-ud-item w-45">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Email</span>
                  <span className="profile-ud-value">{referee?.email}</span>
                </div>
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Phone Number</span>
                  <span className="profile-ud-value">
                    {referee?.phoneNumber}
                  </span>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </Block>
    </Card>
  );
};

export default EmployeeInfoRefereeBlock;
