const convertValuesToArray = (obj = {}) => {
  return Object.values(obj);
};

const convertObjectToArray = (obj = {}) => {
  return Object.entries(obj);
};

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export { convertValuesToArray, convertObjectToArray, getKeyByValue };
