import React, { useState } from "react";
import { Card } from "reactstrap";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { BlockBetween, BlockTitle } from "../../Component";
import DropzoneInput from "../../input/dropzone/DropzoneInput";
import UpdatableText from "../../shared/UpdatabaleText";
import BlockUpdateButton from "../../button/BlockUpdateButton";

import { useBeManualFetcher } from "../../../hooks/useBeManualFetcher";
import newsService from "../../../services/newsService";
import { IMAGE_KEYS, LANGUAGE_KEYS } from "../../../utilities/constants";
import { imageToFileConvert } from "../../../utilities/imageUtils";
import EventEmitter, { EVENT_TOPICS } from "../../../utilities/eventUtils";

const NewsImagesBlock = ({ enImage, ruImage, uzImage }) => {
  const { handleSubmit } = useForm();
  const { newsId } = useParams();
  const [onFetch] = useBeManualFetcher();

  const [hasEditClicked, setHasEditClicked] = useState(false);
  const [enImageState, setEnImage] = useState([]);
  const [ruImageState, setRuImage] = useState([]);
  const [uzImageState, setUzImage] = useState([]);

  function handleOnEditClicked() {
    setHasEditClicked((prev) => !prev);
  }

  async function onFormSubmit() {
    let newFormData = new FormData();
    newFormData.append("newsId", newsId);

    if (enImageState?.[0])
      newFormData.append(
        [LANGUAGE_KEYS.EN],
        imageToFileConvert(enImageState?.[0], IMAGE_KEYS.COVER_IMAGE)
      );

    if (ruImageState?.[0])
      newFormData.append(
        [LANGUAGE_KEYS.RU],
        imageToFileConvert(ruImageState?.[0], IMAGE_KEYS.COVER_IMAGE)
      );

    if (uzImageState?.[0])
      newFormData.append(
        [LANGUAGE_KEYS.UZ],
        imageToFileConvert(uzImageState?.[0], IMAGE_KEYS.COVER_IMAGE)
      );

    return await onFetch({
      action: () => newsService.uploadNewsFiles(newFormData),
      onLoad: (response) => {
        EventEmitter.emit(EVENT_TOPICS.ON_NEWS_UPDATED, {
          updatedNews: response,
        });

        setHasEditClicked(false);
      },
      successMessage: "News updated successfully",
    });
  }

  return (
    <Card className="card-inner">
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <BlockBetween>
          <BlockTitle tag="h5">Images</BlockTitle>
          <BlockUpdateButton
            hasEditClicked={hasEditClicked}
            handleOnEditClicked={handleOnEditClicked}
          />
        </BlockBetween>
        <div className="profile-ud-list position-relative mt-3">
          <div className="profile-ud-item">
            <UpdatableText
              title="Image"
              defaultValue={
                <img className="w-40 h-40" src={enImage} alt={enImage} />
              }
              editableValueComponent={
                <DropzoneInput files={enImageState} setFiles={setEnImage} />
              }
              onEditClicked={hasEditClicked}
            />
          </div>
          <div className="profile-ud-item">
            <UpdatableText
              title="Изображение"
              defaultValue={
                <img className="w-40 h-40" src={ruImage} alt={ruImage} />
              }
              editableValueComponent={
                <DropzoneInput files={ruImageState} setFiles={setRuImage} />
              }
              onEditClicked={hasEditClicked}
            />
          </div>
          <div className="profile-ud-item">
            <UpdatableText
              title="Rasm"
              defaultValue={
                <img className="w-40 h-40" src={uzImage} alt={uzImage} />
              }
              editableValueComponent={
                <DropzoneInput files={uzImageState} setFiles={setUzImage} />
              }
              onEditClicked={hasEditClicked}
            />
          </div>
        </div>
      </form>
    </Card>
  );
};

export default NewsImagesBlock;
