import React, { useCallback } from "react";

import { useSearchParams } from "react-router-dom";
import * as XLSX from "xlsx";

import MainPageTableHeader from "../../layout/body/MainPageTableHeader";
import MainPageTableBody from "../../layout/body/MainPageTableBody";
import MainPageHOC from "../../layout/hoc/MainPageHOC";

import { PaginationComponent } from "../../components/Component";
import FetcherComponent from "../../components/shared/FetcherComponent";
import MainPageHeader from "../../components/shared/MainPageHeader";

import employeeService from "../../services/employeeService";
import { useEmployeePageContext } from "../../hooks/useEmployeePageContext";
import { useBeManualFetcher } from "../../hooks/useBeManualFetcher";

const EmployeesPage = () => {
  const {
    employees,
    setEmployees,
    currentPage,
    setCurrentPage,
    totalPages,
    setTotalPages,
    totalRecords,
    setTotalRecords,
    setPageSize,
    pageSize,
  } = useEmployeePageContext();
  const [onFetch] = useBeManualFetcher();

  const [searchParams] = useSearchParams();
  const currentPageQueryString = searchParams.get("current-page");
  const pageSizeQueryString = searchParams.get("page-size");

  const fetchUsers = useCallback(
    async () =>
      await employeeService.getEmployees({
        currentPage: currentPageQueryString,
        pageSize: pageSizeQueryString,
      }),
    [currentPageQueryString, pageSizeQueryString]
  );

  const handleOnLoadFetchedUsers = useCallback(
    (res) => {
      setEmployees(res?.data);
      setCurrentPage(res?.currentPage);
      setTotalPages(res?.totalPages);
      setTotalRecords(res?.totalRecords);
      setPageSize(res?.pageSize);
    },
    [setEmployees, setCurrentPage, setTotalPages, setTotalRecords, setPageSize]
  );

  const employeeDataChecker = useCallback(
    () => (employees?.length > 0 ? true : false),
    [employees]
  );

  const onExportClick = async () => {
    const copiedEmployees = [...employees];
    const employeesToExport = copiedEmployees?.filter(
      (employee) => employee?.check === true
    );
    const newWorkBook = XLSX.utils.book_new();
    const newWorkSheet = XLSX.utils.json_to_sheet(employeesToExport);
    const body = employeesToExport?.map((employee) => employee?.id);

    XLSX.utils.book_append_sheet(newWorkBook, newWorkSheet, "NewSheet1");

    return await onFetch({
      action: () => employeeService.updateEmployeeStatus("VIEWED", body),
      onLoad: (result) => {
        XLSX.writeFile(newWorkBook, "NewFile.xlsx");
        const restEmployees = copiedEmployees.filter(
          (employee) => employee?.check !== true
        );

        setEmployees([...restEmployees, ...result]);
      },
      successMessage: "Excel file successfully generated",
    });
  };

  return (
    <MainPageHOC
      headerTitle={"Employees"}
      pageHeader={
        <MainPageHeader
          totalRecords={totalRecords}
          pageName="Employees"
          onExportClick={onExportClick}
          disableExportButton={employees?.find(
            (employee) => employee?.check === true
          )}
          showExportButton={true}
        />
      }
      tableHeader={
        <MainPageTableHeader pageSize={pageSize} setPageSize={setPageSize} />
      }
      tableBody={
        <FetcherComponent action={fetchUsers} onLoad={handleOnLoadFetchedUsers}>
          <>
            <MainPageTableBody
              pageName={"employees"}
              users={employees}
              setUsers={setEmployees}
            />
            <div className="card-inner">
              <PaginationComponent
                currentPageNumber={currentPage}
                totalPages={totalPages}
                totalRecords={totalRecords}
                status={pageSizeQueryString}
              />
            </div>
          </>
        </FetcherComponent>
      }
      hasDataForRendering={employeeDataChecker}
    />
  );
};
export default EmployeesPage;
