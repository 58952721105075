import React, { useState, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  BlockTitle,
  Row,
  Col,
  Button,
  OutlinedInput,
  InputSwitch,
  RSelect,
} from "../../components/Component";
import DropZoneInput from "../../components/input/dropzone/DropzoneInput";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import FormWithLabel from "../../components/input/label/FormWithLabel";
import RichTextEditor from "../../components/input/rich-text/RichTextEditor";

import newsService from "../../services/newsService";
import categoryService from "../../services/categoryService";
import { useBeManualFetcher } from "../../hooks/useBeManualFetcher";
import {
  LANGUAGE_KEYS,
  IMAGE_KEYS,
  LANGUAGE_WRAPPERS,
  LINK_TYPES,
} from "../../utilities/constants";
import { appendToFormData } from "../../utilities/formDataUtils";
import { imageToFileConvert } from "../../utilities/imageUtils";

const NewsCreatePage = () => {
  const navigate = useNavigate();
  const { errors, register, handleSubmit } = useForm();
  const [onFetch, isLoading] = useBeManualFetcher();

  const [isAtTop, setIsAtTop] = useState(false);
  const [isLatestNews, setIsLatestNews] = useState(false);
  const [latestNewsImage, setLatestNewsImage] = useState([]);
  const [uzImage, setUzImage] = useState([]);
  const [ruImage, setRuImage] = useState([]);
  const [enImage, setEnImage] = useState([]);
  const [categories, setCategories] = useState();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [enContent, setEnContent] = useState([
    {
      type: "paragraph",
      children: [{ text: "Write some text" }],
    },
  ]);
  const [ruContent, setRuContent] = useState([
    {
      type: "paragraph",
      children: [{ text: "Напишите какой-нибудь текст" }],
    },
  ]);
  const [uzContent, setUzContent] = useState([
    {
      type: "paragraph",
      children: [{ text: "Matnni yozing" }],
    },
  ]);

  async function fetchCategories() {
    await onFetch({
      action: () =>
        categoryService.getCategories({
          pageSize: 100,
        }),
      onLoad: (response) => setCategories(response?.data),
    });
  }

  useEffect(() => fetchCategories(), []);

  async function onFormSubmit(form) {
    const { uzTitle, ruTitle, enTitle } = form;

    const requestBody = {
      title: {
        [LANGUAGE_KEYS.EN]: enTitle,
        [LANGUAGE_KEYS.RU]: ruTitle,
        [LANGUAGE_KEYS.UZ]: uzTitle,
      },
      description: {
        [LANGUAGE_KEYS.EN]: JSON.stringify(enContent),
        [LANGUAGE_KEYS.RU]: JSON.stringify(ruContent),
        [LANGUAGE_KEYS.UZ]: JSON.stringify(uzContent),
      },
      isAtTop,
      latestNews: {
        isLatestNews,
      },
      categoryIds: selectedCategories?.map((category) => category?.id),
    };

    return await onFetch({
      action: async () => await newsService.createNews(requestBody),
      onLoad: async (response) => {
        let newFormData = new FormData();

        newFormData.append("newsId", response?.id);

        isLatestNews &&
          latestNewsImage.length > 0 &&
          newFormData.append(
            "latestNews",
            imageToFileConvert(latestNewsImage?.[0], IMAGE_KEYS.COVER_IMAGE)
          );

        if (enImage?.length > 0) {
          const file = enImage?.[0];

          appendToFormData(newFormData, {
            [LANGUAGE_KEYS.EN]: imageToFileConvert(
              file,
              IMAGE_KEYS.COVER_IMAGE
            ),
          });
        }

        if (uzImage?.length > 0) {
          const file = uzImage?.[0];

          appendToFormData(newFormData, {
            [LANGUAGE_KEYS.UZ]: imageToFileConvert(
              file,
              IMAGE_KEYS.COVER_IMAGE
            ),
          });
        }

        if (ruImage?.length > 0) {
          const file = ruImage?.[0];

          appendToFormData(newFormData, {
            [LANGUAGE_KEYS.RU]: imageToFileConvert(
              file,
              IMAGE_KEYS.COVER_IMAGE
            ),
          });
        }

        await newsService.uploadNewsFiles(newFormData).then(() => {
          navigate("/news", { replace: true });
        });
      },
      successMessage: "News created successfully",
    });
  }

  const categoryOptions = useMemo(
    () =>
      categories?.map((category) => {
        return {
          id: category?.id,
          value: category?.id,
          label: category?.title?.[LANGUAGE_KEYS.EN],
        };
      }),
    [categories]
  );

  return (
    <React.Fragment>
      <Head title={"Create News"}></Head>
      <Content>
        <Button
          color="white border-light"
          onClick={() => navigate("/news")}
          iconName="arrow-left"
          title={"Back"}
        />
        <BlockTitle tag="h5" className="my-3">
          Create News
        </BlockTitle>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Row className="g-3">
            <Col size="12">
              <FormWithLabel noWrap label={"Title"}>
                <Row>
                  <Col size="4">
                    <OutlinedInput
                      type="text"
                      className="form-control"
                      label="O'zbekcha"
                      name="uzTitle"
                      forwardRef={register({
                        required: "This field is required",
                      })}
                      errors={errors}
                    />
                  </Col>
                  <Col size="4">
                    <OutlinedInput
                      type="text"
                      label="На русском"
                      className="form-control"
                      name="ruTitle"
                      forwardRef={register({
                        required: "This field is required",
                      })}
                    />
                  </Col>
                  <Col size="4">
                    <OutlinedInput
                      type="text"
                      className="form-control"
                      label="In English"
                      name="enTitle"
                      forwardRef={register({
                        required: "This field is required",
                      })}
                      errors={errors}
                    />
                  </Col>
                </Row>
              </FormWithLabel>
            </Col>
            <Col size={"12"}>
              <FormWithLabel label="Content in English" noWrap>
                <RichTextEditor value={enContent} setValue={setEnContent} />
              </FormWithLabel>
            </Col>
            <Col size={"12"}>
              <FormWithLabel label="Содержание на Русском языке" noWrap>
                <RichTextEditor value={ruContent} setValue={setRuContent} />
              </FormWithLabel>
            </Col>
            <Col size={"12"}>
              <FormWithLabel label="Kontent O'zbek tilida" noWrap>
                <RichTextEditor value={uzContent} setValue={setUzContent} />
              </FormWithLabel>
            </Col>
            <Col size="3">
              <FormWithLabel
                label="Is At Latest News"
                className={"d-flex"}
                noWrap
              >
                <InputSwitch
                  id="latestNews"
                  style={{ paddingLeft: "4.75rem" }}
                  onClick={() => setIsLatestNews((prev) => !prev)}
                  checked={isLatestNews}
                />
              </FormWithLabel>
            </Col>
            <Col size="6">
              <FormWithLabel label="Is At Top" className={"d-flex"} noWrap>
                <InputSwitch
                  id="banner"
                  style={{ paddingLeft: "4.75rem" }}
                  onClick={() => setIsAtTop((prev) => !prev)}
                  checked={isAtTop}
                />
              </FormWithLabel>
            </Col>
            {isLatestNews && (
              <Col size="12">
                <FormWithLabel label={`Image For Latest News Banner`} noWrap>
                  <DropZoneInput
                    text={"Drag and drop the image here, or click select image"}
                    files={latestNewsImage}
                    setFiles={setLatestNewsImage}
                  />
                </FormWithLabel>
              </Col>
            )}
            <Col size="4">
              <FormWithLabel label={`Image (${IMAGE_KEYS.COVER_IMAGE})`} noWrap>
                <DropZoneInput
                  text={"Drag and drop the image here, or click select image"}
                  files={enImage}
                  setFiles={setEnImage}
                />
              </FormWithLabel>
            </Col>
            <Col size="4">
              <FormWithLabel
                label={`Изображение (${IMAGE_KEYS.COVER_IMAGE})`}
                noWrap
              >
                <DropZoneInput
                  text={"Drag and drop the image here, or click select image"}
                  files={ruImage}
                  setFiles={setRuImage}
                />
              </FormWithLabel>
            </Col>
            <Col size="4">
              <FormWithLabel label={`Rasm (${IMAGE_KEYS.COVER_IMAGE})`} noWrap>
                <DropZoneInput
                  text={"Drag and drop the image here, or click select image"}
                  files={uzImage}
                  setFiles={setUzImage}
                />
              </FormWithLabel>
            </Col>
            <Col size="12">
              <FormWithLabel label={"News Categories"} noWrap>
                <RSelect
                  options={categoryOptions}
                  onChange={(e) => setSelectedCategories(e)}
                  value={selectedCategories}
                  isMulti
                />
              </FormWithLabel>
            </Col>
            <Col size="12" className="text-right">
              <Button
                color="primary"
                type="submit"
                iconName="save"
                title={"Save"}
                isLoading={isLoading}
              />
            </Col>
          </Row>
        </form>
      </Content>
    </React.Fragment>
  );
};

export default NewsCreatePage;
