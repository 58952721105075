import { EventEmitter } from "fbemitter";

export const EVENT_TOPICS = {
  ON_CATEGORY_DELETED: "OnCategoryDeleted",
  ON_EMPLOYEE_DELETED: "OnEmployeeDeleted",
  ON_EMPLOYEE_STATUS_UPDATED: "OnEmployeeStatusUpdated",
  ON_NEWS_DELETED: "OnNewsDeleted",
  ON_NEWS_SEQUENCE_CHANGED: "OnNewsSequenceChanged",
  ON_NEWS_UPDATED: "OnNewsUpdated",
  ON_STUDENT_DELETED: "OnStudentDeleted",
  ON_STUDENT_UPDATED: "OnStudentUpdated",
  ON_STUDENT_STATUS_UPDATED: "OnStudentStatusUpdated",
};

export default new EventEmitter();
