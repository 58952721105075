import { Editor, Transforms, Element as SlateElement } from "slate";
import { LIST_TYPES, TEXT_ALIGN_TYPES } from "./constants";
import { convertValuesToArray } from "./objectUtils";

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    convertValuesToArray(TEXT_ALIGN_TYPES).includes(format) ? "align" : "type"
  );
  const isList = convertValuesToArray(LIST_TYPES).includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      convertValuesToArray(LIST_TYPES).includes(n.type) &&
      !convertValuesToArray(TEXT_ALIGN_TYPES).includes(format),
    split: true,
  });
  let newProperties;
  if (convertValuesToArray(TEXT_ALIGN_TYPES).includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};
