import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull";

import {
  getCategories,
  getCategoryById,
  createCategory,
  updateCategory,
  removeCategory,
  linkCategoryToNews,
} from "../repositories/categoryRepository";

export default {
  getCategories: async ({ currentPage, pageSize }) => {
    const pageNumber =
      isUndefined(currentPage) || isNull(currentPage) ? 1 : currentPage;
    const itemsPerPage =
      isUndefined(pageSize) || isNull(pageSize) ? 20 : pageSize;

    return await getCategories({
      "current-page": pageNumber,
      "page-size": itemsPerPage,
    });
  },

  getCategoryById: async (id) => await getCategoryById(id),

  createCategory: async (body) => await createCategory(body),

  updateCategory: async (id, body) => await updateCategory(id, body),

  removeCategory: async (id) => await removeCategory(id),

  linkCategoryToNews: async (id, type, body) =>
    await linkCategoryToNews(id, type, body),
};
