import React from "react";
import { useTranslation } from "react-i18next";

const UpdatableText = ({
  title,
  value,
  defaultValue,
  type,
  name,
  onChange,
  register,
  readOnly,
  onEditClicked,
  isWider = false,
  editableValueComponent,
  onClick,
  href,
  className,
}) => {
  const { t } = useTranslation();

  const renderEditableComponent = editableValueComponent && onEditClicked && (
    <>{editableValueComponent}</>
  );

  const renderEditableInput = onEditClicked && !editableValueComponent && (
    <input
      type={type}
      step={1}
      name={name}
      className="form-control"
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
      ref={register ? register() : null}
      readOnly={readOnly}
    />
  );

  const renderValue = !onEditClicked && (
    <a
      href={href ?? null}
      target={"_black"}
      className={href?.length < 1 && "no-underline"}
      // onClick={(event) =>
      //   href?.length < 1 && !enableValueClick && event.preventDefault()
      // }
    >
      <div className="profile-ud-value" onClick={onClick}>
        {defaultValue ?? value}
      </div>
    </a>
  );

  return (
    <div className={`profile-ud ${isWider && "wider"} ${className ?? null}`}>
      <div className="profile-ud-label">{title ? `${t(title)}:` : null}</div>
      <div
        className={`${`form-control-wrap ${isWider ? "ml-2" : "mt-1 mb-4"}`}`}
        onClick={() => console.log("div")}
      >
        {renderEditableComponent}
        {renderEditableInput}
        {renderValue}
      </div>
    </div>
  );
};

export default UpdatableText;
