import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Block, BlockContent, Button } from "../../components/Component";
import Head from "../../layout/head/Head";

import ErrorImage from "../../assets/images/error-404.svg";

const Error404Modern = () => {
  const { t } = useTranslation();
  return (
    <>
      <Head title="404" />
      <Block className="nk-block-middle wide-md mx-auto">
        <BlockContent className="nk-error-ld text-center">
          <img className="nk-error-gfx" src={ErrorImage} alt="error" />
          <div className="wide-xs mx-auto">
            <h3 className="nk-error-title">{t("Oops! Why you’re here?")}</h3>
            <p className="nk-error-text">
              {t(
                "We are very sorry for inconvenience. It looks like you’re trying to access a page that either has been deleted or never existed."
              )}
            </p>
            <Link to={`${process.env.PUBLIC_URL}/`}>
              <Button
                color="primary"
                size="lg"
                className="mt-2"
                title={"Back To Home"}
              />
            </Link>
          </div>
        </BlockContent>
      </Block>
    </>
  );
};
export default Error404Modern;
