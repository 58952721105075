import React, { useState, useEffect } from "react";
import { Form, FormGroup, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";

import { ERROR_MESSAGES } from "../../utilities/constants";
import { useStatusContext } from "../../hooks/useStatusContext";
import { useAuthContext } from "../../hooks/useAuthContext";

import Logo from "../../assets/images/logo.png";
import LogoDark from "../../assets/images/logo.png";
import { getAccessToken } from "../../services/authService";

const Login = () => {
  const [passState, setPassState] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { onErrorStatus } = useStatusContext();
  const { errors, register, handleSubmit } = useForm();
  const { login } = useAuthContext();
  const navigate = useNavigate();
  const token = getAccessToken();

  const onFormSubmit = async () => {
    setIsLoading(true);

    try {
      await login({
        email,
        password,
      });
    } catch (err) {
      onErrorStatus({
        title: "Error on Sign In.",
        message: ERROR_MESSAGES[err.beErrorCode] ?? err.message,
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);

  return (
    <React.Fragment>
      <Head title="Login" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg"
                src={Logo}
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg"
                src={LogoDark}
                alt="logo-dark"
              />
            </Link>
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Sign-In</BlockTitle>
                <BlockDes>
                  <p>
                    Access PhTU Admin Dashboard using your email and password.
                  </p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="default-01"
                    name="name"
                    ref={register({ required: "This field is required" })}
                    placeholder="Enter your email address or username"
                    className="form-control-lg form-control"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.name && (
                    <span className="invalid">{errors.name.message}</span>
                  )}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${
                      passState ? "is-hidden" : "is-shown"
                    }`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon
                      name="eye-off"
                      className="passcode-icon icon-hide"
                    ></Icon>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="password"
                    name="passcode"
                    ref={register({ required: "This field is required" })}
                    placeholder="Enter your passcode"
                    className={`form-control-lg form-control ${
                      passState ? "is-hidden" : "is-shown"
                    }`}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {errors.passcode && (
                    <span className="invalid">{errors.passcode.message}</span>
                  )}
                </div>
                <Link
                  className="link link-secondary link-sm mt-2 d-flex justify-content-end"
                  to={`${process.env.PUBLIC_URL}/auth-reset`}
                >
                  Forgot Password?
                </Link>
              </FormGroup>
              <FormGroup>
                {isLoading ? (
                  <Button
                    size="lg"
                    className="btn-block"
                    type="submit"
                    color="primary"
                    disabled={true}
                  >
                    <Spinner size="sm" color="light" />
                  </Button>
                ) : (
                  <Button
                    size="lg"
                    className="btn-block"
                    type="submit"
                    color="primary"
                    title={"Sign in"}
                  />
                )}
              </FormGroup>
            </Form>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Login;
