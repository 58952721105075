import { useSlate } from "slate-react";
import { toggleMark, isMarkActive } from "../../../utilities/richEditorUtils";
import { Icon } from "../../Component";
import EditorButton from "./EditorButton";

const EditorMarkButton = ({ format, icon }) => {
  const editor = useSlate();

  return (
    <EditorButton
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon name={icon} />
    </EditorButton>
  );
};

export default EditorMarkButton;
