import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
} from "../Component";

const MainPageHeader = ({
  totalRecords,
  pageName,
  redirectTo,
  onExportClick,
  showAddButton,
  showExportButton,
  disableExportButton,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <BlockHead size="sm">
      <BlockBetween>
        <BlockHeadContent>
          <BlockTitle tag="h3" page>
            {pageName}
          </BlockTitle>
          <BlockDes className="text-soft">
            <p>
              {t("You have total")} {totalRecords} {t(pageName).toLowerCase()}.
            </p>
          </BlockDes>
        </BlockHeadContent>
        {showAddButton && (
          <Button
            className={"btn-icon"}
            color={"primary"}
            iconName={"plus"}
            onClick={() => navigate(redirectTo)}
          />
        )}
        {showExportButton && (
          <BlockHeadContent>
            <Button
              title={"Export"}
              color={"white"}
              iconName={"file-download"}
              onClick={onExportClick}
              disabled={!disableExportButton}
            />
          </BlockHeadContent>
        )}
      </BlockBetween>
    </BlockHead>
  );
};

export default MainPageHeader;
