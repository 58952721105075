import React from "react";
import { Card } from "reactstrap";

import { Block, BlockHead, BlockTitle } from "../../Component";

const EmployeeInfoLanguageBlock = ({ user }) => {
  return (
    <Card className="card-inner">
      <Block>
        <BlockHead>
          <BlockTitle tag="h5">Languages</BlockTitle>
        </BlockHead>
        {user?.languages?.map((language, index) => (
          <React.Fragment key={index}>
            {index > 0 && <div className="nk-divider divider md"></div>}
            <div className="profile-ud-list">
              <div className="profile-ud-item w-45 mr-5">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Language</span>
                  <span className="profile-ud-value">{language?.language}</span>
                </div>
              </div>
              <div className="profile-ud-item w-45">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Level</span>
                  <span className="profile-ud-value">
                    {language?.languageLevel}
                  </span>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </Block>
    </Card>
  );
};

export default EmployeeInfoLanguageBlock;
