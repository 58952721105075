import Cookies from "js-cookie";

export const setCookie = (name, value, options = {}) => {
  Cookies.set(name, value, { ...options, sameSite: "Strict", secure: true });
};

export const removeCookie = (name) => {
  Cookies.remove(name);
};

export const getCookie = (name) => {
  return Cookies.get(name);
};
