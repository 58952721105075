import { getCookie, removeCookie } from "../utilities/storageUtils";

export const getAccessToken = () => {
  const accessToken = getCookie("accessToken");
  if (!accessToken) return;
  return accessToken ?? null;
};

export const getRefreshToken = () => {
  const refreshToken = getCookie("refreshToken");
  if (!refreshToken) return;
  return refreshToken ?? null;
};

export const logout = async () => {
  removeCookie("accessToken");
  removeCookie("refreshToken");
};
