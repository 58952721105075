import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";

export const DataTable = ({
  className,
  bodyClassName,
  title,
  bordered = true,
  ...props
}) => {
  return (
    <Card
      className={`${bordered ? "card-bordered" : ""} ${
        className ? className : ""
      }`}
    >
      <div className="card-inner-group">{props.children}</div>
    </Card>
  );
};

export const DataTableTitle = ({ ...props }) => {
  return (
    <div className="card-inner position-relative card-tools-toggle">
      <div className="card-title-group">{props.children}</div>
    </div>
  );
};

export const DataTableBody = ({ compact, className, bodyclass, ...props }) => {
  return (
    <div className={`card-inner p-0 ${className ? className : ""}`}>
      <div
        className={`nk-tb-list nk-tb-ulist ${bodyclass ? bodyclass : ""} ${
          compact ? "is-compact" : ""
        }`}
      >
        {props.children}
      </div>
    </div>
  );
};

export const DataTableHead = ({ ...props }) => {
  return <div className="nk-tb-item nk-tb-head">{props.children}</div>;
};

export const DataTableRow = ({
  noText,
  text,
  className,
  size,
  fontSize,
  maxWidth,
  ...props
}) => {
  const rowClass = classNames({
    "nk-tb-col": true,
    [`${className}`]: className,
    [`tb-col-${size}`]: size,
  });
  const { t } = useTranslation();

  return (
    <div
      className={rowClass}
      style={{ fontSize: fontSize, maxWidth: maxWidth }}
    >
      {noText ? (
        <>{props.children}</>
      ) : (
        <span className="sub-text">{t(text)}</span>
      )}
    </div>
  );
};

export const DataTableItem = ({ className, provided, ...props }) => {
  return (
    <div
      className={`nk-tb-item ${className ? className : ""}`}
      {...provided?.dragHandleProps}
      {...provided?.draggableProps}
      ref={provided?.innerRef}
    >
      {props.children}
    </div>
  );
};
