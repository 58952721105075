import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useBeManualFetcher } from "../../hooks/useBeManualFetcher";
import EventEmitter from "../../utilities/eventUtils";

const DraggableListHOC = ({
  children,
  data,
  action,
  eventTopic,
  className,
}) => {
  const [onFetch] = useBeManualFetcher();

  const onDragEnd = async (result) => {
    const changedSequnceNumber = data?.find(
      (item, index) => index === result.destination.index
    )?.sequenceNumber;

    EventEmitter.emit(eventTopic, {
      result,
    });

    await onFetch({
      action: async () =>
        await action({
          draggableId: result?.draggableId,
          sequenceNumber: changedSequnceNumber,
        }),
      onLoad: () => {},
      successMessage: "Sequence successfully changed",
    });
  };

  return (
    <div className={className}>
      <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
        <Droppable droppableId="Droppable">
          {({ droppableProps, innerRef, placeholder }) => (
            <div ref={innerRef} {...droppableProps}>
              {children}
              {placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default DraggableListHOC;
