import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const InputSwitch = ({ label, id, checked, onClick, className, style }) => {
  const { t } = useTranslation();
  const [inputCheck, setCheck] = useState(checked);

  return (
    <React.Fragment>
      <div
        className={`custom-control custom-switch ${className ? className : ""}`}
        style={style}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          onClick={() => {
            setCheck((prev) => !prev);
            onClick();
          }}
          defaultChecked={inputCheck}
          id={id}
        />
        <label className="custom-control-label" htmlFor={id}>
          {t(label)}
        </label>
      </div>
    </React.Fragment>
  );
};

export default InputSwitch;
