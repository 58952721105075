import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import * as XLSX from "xlsx";

import MainPageHOC from "../../layout/hoc/MainPageHOC";
import MainPageTableHeader from "../../layout/body/MainPageTableHeader";

import PaginationComponent from "../../components/pagination/PaginationComponent";
import FetcherComponent from "../../components/shared/FetcherComponent";
import MainPageHeader from "../../components/shared/MainPageHeader";

import studentService from "../../services/studentService";

import { useStudentPageContext } from "../../hooks/useStudentPageContext";
import { useBeManualFetcher } from "../../hooks/useBeManualFetcher";
import StudentsPageTableBody from "../../components/studentPage/StudentsPageTableBody";

const StudentsPage = () => {
  const {
    students,
    setStudents,
    currentPage,
    setCurrentPage,
    totalPages,
    setTotalPages,
    totalRecords,
    setTotalRecords,
    setPageSize,
    pageSize,
  } = useStudentPageContext();
  const [onFetch] = useBeManualFetcher();

  const [searchParams] = useSearchParams();
  const currentPageQueryString = searchParams.get("current-page");
  const pageSizeQueryString = searchParams.get("page-size");

  const fetchUsers = useCallback(
    async () =>
      await studentService.getStudents({
        currentPage: currentPageQueryString,
        pageSize: pageSizeQueryString,
      }),
    [currentPageQueryString, pageSizeQueryString]
  );

  const handleOnLoadFetchedUsers = useCallback(
    (res) => {
      setStudents(res?.data);
      setCurrentPage(res?.currentPage);
      setTotalPages(res?.totalPages);
      setTotalRecords(res?.totalRecords);
      setPageSize(res?.pageSize);
    },
    [setStudents, setCurrentPage, setTotalPages, setTotalRecords, setPageSize]
  );

  const studentDataChecker = useCallback(
    () => (students?.length > 0 ? true : false),
    [students]
  );

  const onExportClick = async () => {
    const copiedStudents = [...students];
    const studentsToExport = copiedStudents?.filter(
      (student) => student?.check === true
    );
    const newWorkBook = XLSX.utils.book_new();
    const newWorkSheet = XLSX.utils.json_to_sheet(studentsToExport);
    const body = studentsToExport?.map((student) => student?.id);

    XLSX.utils.book_append_sheet(newWorkBook, newWorkSheet, "NewSheet1");

    return await onFetch({
      action: () => studentService.updateStudentStatus("VIEWED", body),
      onLoad: (result) => {
        XLSX.writeFile(newWorkBook, "NewFile.xlsx");
        const restStudents = copiedStudents.filter(
          (student) => student?.check !== true
        );

        setStudents([...restStudents, ...result]);
      },
      successMessage: "Excel file successfully created",
    });
  };

  return (
    <MainPageHOC
      headerTitle={"Students"}
      pageHeader={
        <MainPageHeader
          totalRecords={totalRecords}
          pageName="Students"
          redirectTo={"add"}
          disableExportButton={students?.find(
            (student) => student?.check === true
          )}
          showExportButton={true}
          onExportClick={onExportClick}
        />
      }
      tableHeader={
        <MainPageTableHeader pageSize={pageSize} setPageSize={setPageSize} />
      }
      tableBody={
        <FetcherComponent action={fetchUsers} onLoad={handleOnLoadFetchedUsers}>
          <>
            <StudentsPageTableBody users={students} setUsers={setStudents} />
            <div className="card-inner">
              <PaginationComponent
                currentPageNumber={currentPage}
                totalPages={totalPages}
                totalRecords={totalRecords}
                status={pageSizeQueryString}
              />
            </div>
          </>
        </FetcherComponent>
      }
      hasDataForRendering={studentDataChecker}
    />
  );
};
export default StudentsPage;
