import backendHttpRequest from "./backendHttpRequest";
import { config } from "../configs/repositoryConfig";
import { convertToQueryString } from "../utilities/httpUtils";
import { replaceAllStringByKeys } from "../utilities/stringUtils";

export const getCategories = async (filteredObject) => {
  const queryString = convertToQueryString(filteredObject);
  const resourceObject = {
    ...config.backendApiSettings.resources.getCategories,
  };
  const endpoint = resourceObject.endpoint;
  resourceObject.endpoint = `${endpoint}${
    queryString ? `?${queryString}` : ""
  }`;

  const apiCategories = await backendHttpRequest({ resourceObject });

  return apiCategories;
};

export const getCategoryById = async (id) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.getCategoryById,
  };
  const endpoint = resourceObject.endpoint;

  resourceObject.endpoint = endpoint.replace(":id", id);

  const apiCategories = await backendHttpRequest({ resourceObject });

  return apiCategories;
};

export const createCategory = async (body) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.createCategory,
  };

  const apiCategory = await backendHttpRequest({
    resourceObject,
    body,
  });

  return apiCategory;
};

export const updateCategory = async (id, body) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.updateCategory,
  };

  resourceObject.endpoint = replaceAllStringByKeys(
    resourceObject.endpoint,
    [":id"],
    [id]
  );

  const updatedCategory = await backendHttpRequest({
    resourceObject,
    body,
  });

  return updatedCategory;
};

export const removeCategory = async (id) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.deleteCategory,
  };
  const endpoint = resourceObject.endpoint;

  resourceObject.endpoint = endpoint.replace(":id", id);

  await backendHttpRequest({ resourceObject });

  return id;
};

export async function linkCategoryToNews(id, type, body) {
  const resourceObject = {
    ...config.backendApiSettings.resources.linkCategoryToNews,
  };

  resourceObject.endpoint = replaceAllStringByKeys(
    resourceObject.endpoint,
    [":id", ":type"],
    [id, type]
  );

  return await backendHttpRequest({
    resourceObject,
    body,
  });
}
