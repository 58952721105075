import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull";

import {
  getStudents,
  getStudentById,
  updateStudentStatus,
  removeStudent,
  updateStudent,
  updateStudentApplicationStatus,
  exportStudent,
  uploadStudentFiles,
} from "../repositories/studentRepository";

export default {
  getStudents: async ({ currentPage, pageSize }) => {
    const pageNumber =
      isUndefined(currentPage) || isNull(currentPage) ? 1 : currentPage;
    const itemsPerPage =
      isUndefined(pageSize) || isNull(pageSize) ? 20 : pageSize;

    return await getStudents({
      "current-page": Number(pageNumber),
      "page-size": itemsPerPage,
    });
  },

  getStudentById: async (id) => await getStudentById(id),

  updateStudent: async (id, body) => await updateStudent(id, body),

  uploadStudentFiles: async (formData) => await uploadStudentFiles(formData),

  updateStudentStatus: async (status, body) =>
    await updateStudentStatus(status, body),

  updateStudentApplicationStatus: async (id, status, body) => {
    let requestBody = {};

    if (body) {
      requestBody = body;
    }

    return await updateStudentApplicationStatus(id, status, requestBody);
  },

  removeStudent: async (id) => await removeStudent(id),

  exportStudent: async (body) => await exportStudent(body),
};
