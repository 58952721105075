import React from "react";

import { DataTableBody, DataTableHead, DataTableRow } from "../Component";
import NewsCategoryPageTableBodyItem from "./NewsCategoryPageTableBodyItem";

import { useNewsCategoryPageContext } from "../../hooks/useNewsCategoryPageContext";

const NewsCategoryPageTableBody = () => {
  const { categories } = useNewsCategoryPageContext();

  return (
    <DataTableBody>
      <DataTableHead>
        <DataTableRow text="Title" />
        <DataTableRow text="Заголовок" />
        <DataTableRow text="Sarlavha" />
        <DataTableRow className="nk-tb-col-tools" noText />
      </DataTableHead>
      {categories?.data?.map((item) => (
        <NewsCategoryPageTableBodyItem
          key={item.id}
          id={item?.id}
          title={item?.title}
        />
      ))}
    </DataTableBody>
  );
};

export default NewsCategoryPageTableBody;
