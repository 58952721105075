import React, { useState, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  BlockTitle,
  Row,
  Col,
  Button,
  OutlinedInput,
  RSelect,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import FormWithLabel from "../../components/input/label/FormWithLabel";

import categoryService from "../../services/categoryService";
import newsService from "../../services/newsService";
import { useBeManualFetcher } from "../../hooks/useBeManualFetcher";
import { LANGUAGE_KEYS, LINK_TYPES } from "../../utilities/constants";
import { appendToFormData } from "../../utilities/formDataUtils";

const NewsCategoryCreatePage = () => {
  const navigate = useNavigate();
  const { errors, register, handleSubmit } = useForm();
  const [onFetch] = useBeManualFetcher();

  const [news, setNews] = useState();
  const [selectedNews, setSelectedNews] = useState([]);

  async function fetchNews() {
    await onFetch({
      action: () =>
        newsService.getNews({
          pageSize: 100,
        }),
      onLoad: (response) => setNews(response?.data),
    });
  }

  useEffect(() => fetchNews(), []);

  async function onFormSubmit(form) {
    const { uzTitle, ruTitle, enTitle } = form;
    const body = {
      title: {
        [LANGUAGE_KEYS.EN]: enTitle,
        [LANGUAGE_KEYS.RU]: ruTitle,
        [LANGUAGE_KEYS.UZ]: uzTitle,
      },
    };

    return await onFetch({
      action: async () => await categoryService.createCategory(body),
      onLoad: async (response) => {
        const body = selectedNews?.map((news) => news?.id);

        await onFetch({
          action: async () =>
            await categoryService.linkCategoryToNews(
              response?.id,
              LINK_TYPES.LINK,
              body
            ),
          onLoad: () => navigate("/news-category", { replace: true }),
        });
      },
      successMessage: "Category created successfully",
    });
  }

  const newsOptions = useMemo(
    () =>
      news?.map((news) => {
        return {
          id: news?.id,
          value: news?.id,
          label: news?.title?.[LANGUAGE_KEYS.EN],
        };
      }),
    [news]
  );

  return (
    <React.Fragment>
      <Head title={"Create News Category"}></Head>
      <Content>
        <Button
          color="white border-light"
          onClick={() => navigate("/news")}
          iconName="arrow-left"
          title={"Back"}
        />
        <BlockTitle tag="h5" className="my-3">
          Create News Category
        </BlockTitle>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Row className="g-3">
            <Col size="12">
              <FormWithLabel noWrap label={"Title"}>
                <Row>
                  <Col size="4">
                    <OutlinedInput
                      type="text"
                      className="form-control"
                      label="O'zbekcha"
                      name="uzTitle"
                      forwardRef={register({
                        required: "This field is required",
                      })}
                      errors={errors}
                    />
                  </Col>
                  <Col size="4">
                    <OutlinedInput
                      type="text"
                      label="На русском"
                      className="form-control"
                      name="ruTitle"
                      forwardRef={register({
                        required: "This field is required",
                      })}
                      errors={errors}
                    />
                  </Col>
                  <Col size="4">
                    <OutlinedInput
                      type="text"
                      className="form-control"
                      label="In English"
                      name="enTitle"
                      forwardRef={register({
                        required: "This field is required",
                      })}
                      errors={errors}
                    />
                  </Col>
                </Row>
              </FormWithLabel>
            </Col>
            <Col size="12">
              <FormWithLabel label={"News To Link"} noWrap>
                <RSelect
                  options={newsOptions}
                  onChange={(e) => setSelectedNews(e)}
                  value={selectedNews}
                  isMulti
                />
              </FormWithLabel>
            </Col>
            <Col size="12" className="text-right">
              <Button
                color="primary"
                type="submit"
                iconName="save"
                title={"Save"}
              />
            </Col>
          </Row>
        </form>
      </Content>
    </React.Fragment>
  );
};

export default NewsCategoryCreatePage;
