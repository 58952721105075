import React from "react";
import { useTranslation } from "react-i18next";

import InputHint from "./InputHint";

const FormWithLabel = ({
  noWrap,
  label,
  className = "",
  inputWrapperClassName = "",
  inputHint,
  inputName,
  errors,
  ...props
}) => {
  const { t } = useTranslation();

  const renderLabel = label && (
    <label className="form-label" htmlFor="product-title">
      {t(label)}
    </label>
  );

  const renderInputHint = inputHint && <InputHint hint={inputHint} />;

  const renderValidationError = errors?.[inputName]?.type === "required" && (
    <p className="invalid">{errors?.[inputName]?.message}</p>
  );

  const renderChild = (children, noWrap) => {
    if (noWrap) return <>{children}</>;

    return (
      <div className={`form-control-wrap ${inputWrapperClassName}`}>
        {children}
      </div>
    );
  };

  return (
    <div className={`form-group ${className}`}>
      {renderLabel}
      {renderInputHint}
      {renderChild(props.children, noWrap)}
      {renderValidationError}
    </div>
  );
};

export default FormWithLabel;
