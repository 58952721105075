import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from "./pages/auth/Login";
import Layout from "./layout/Index";
import RequireAuth from "./routes/RequireAuth";
import StatusContextProvider from "./providers/StatusContextProvider";
import AuthContextProvider from "./providers/AuthContextProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <StatusContextProvider>
      <AuthContextProvider>
        <Suspense fallback={<div />}>
          <QueryClientProvider client={queryClient}>
            <Router>
              <Routes>
                <Route exact path="/auth-login" element={<Login />}></Route>
                <Route
                  path="*"
                  element={
                    <RequireAuth>
                      <Layout />
                    </RequireAuth>
                  }
                />
              </Routes>
            </Router>
          </QueryClientProvider>
        </Suspense>
      </AuthContextProvider>
    </StatusContextProvider>
  );
}

export default App;
