import React from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import isNull from "lodash/isNull";
import { useSearchParams } from "react-router-dom";

import { Icon } from "../Component";
import "./PaginationComponent.css";

const PaginationComponent = ({ currentPageNumber, totalPages, status }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePageChange = (data) => {
    const pageNumber = parseFloat(data.selected) + 1;
    const isPageNumberNull = isNull(pageNumber);

    if (!isPageNumberNull) {
      searchParams.set("current-page", pageNumber);
      setSearchParams(searchParams);
    }
  };

  return (
    <div className="card-inner">
      <ReactPaginate
        initialPage={currentPageNumber - 1}
        disableInitialCallback={status}
        breakLabel="..."
        nextLabel={
          <div className="center">
            <span>{t("Next ")}</span>
            <Icon name="chevrons-right" />
          </div>
        }
        pageRangeDisplayed={3}
        pageCount={totalPages}
        previousLabel={
          <div className="center">
            <Icon name="chevrons-left" />
            <span>{t(" Prev")}</span>
          </div>
        }
        marginPagesDisplayed={1}
        renderOnZeroPageCount={null}
        onPageChange={handlePageChange}
        containerClassName="PaginationComponent-btns"
        pageClassName="PaginationComponent-listItem"
        pageLinkClassName="PaginationComponent-link"
        previousLinkClassName="PaginationComponent-prevBtn"
        nextLinkClassName="PaginationComponent-nextBtn"
        disabledLinkClassName="PaginationComponent-disabled"
        activeClassName="PaginationComponent-active"
        breakClassName="PaginationComponent-break"
      />
    </div>
  );
};

export default PaginationComponent;
