import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "../../Component";

const OutlinedInput = ({
  label,
  size,
  id,
  icon,
  forwardRef,
  name,
  type,
  defaultValue,
  value,
  onChange = () => {},
  errors,
}) => {
  const [focus, setFocus] = useState(true);
  const { t } = useTranslation();

  const renderIcon = icon && (
    <div className="form-icon form-icon-right xl">
      <Icon name={icon}></Icon>
    </div>
  );

  const renderValidationError = errors?.[name] && (
    <span className="invalid">{errors?.[name].message}</span>
  );

  return (
    <div className={`form-control-wrap ${focus ? "focused" : ""}`}>
      {renderIcon}
      <input
        type={type}
        className={`form-control-outlined form-control ${
          size ? `form-control-${size}` : ""
        }`}
        id={id}
        name={name}
        defaultValue={defaultValue}
        value={value}
        onFocus={() => setFocus(true)}
        onChange={(e) => onChange(e.target.value)}
        onBlur={(e) => e.target.value?.length === 0 && setFocus(false)}
        ref={forwardRef}
      />
      <label className="form-label-outlined" htmlFor={id}>
        {t(label)}
      </label>
      {renderValidationError}
    </div>
  );
};

export default OutlinedInput;
