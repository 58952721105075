import React from "react";
import { Link } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { DATE_FORMATS, VACANCIES_ENUM } from "../../utilities/constants";
import { formatDate } from "../../utilities/dateTimeUtils";
import { DataTableItem, DataTableRow, Icon, UserAvatar } from "../Component";
import { useBeManualFetcher } from "../../hooks/useBeManualFetcher";
import employeeService from "../../services/employeeService";
import EventEmitter, { EVENT_TOPICS } from "../../utilities/eventUtils";

const EmployeesPageTableBodyItems = ({ item, onSelectChange }) => {
  const [onFetch] = useBeManualFetcher();

  const handleEmployeeDelete = (id) => {
    onFetch({
      action: async () => await employeeService.removeEmployee(id),
      onLoad: () => {
        EventEmitter.emit(EVENT_TOPICS.ON_EMPLOYEE_DELETED, {
          id,
        });
      },
      successMessage: "Employee deleted successfully",
    });
  };
  return (
    <DataTableItem>
      <DataTableRow className="nk-tb-col-check" noText>
        <div className="custom-control custom-control-sm custom-checkbox notext">
          <input
            type="checkbox"
            className="custom-control-input form-control"
            defaultChecked={item.check}
            id={item.id + "pid-all"}
            key={Math.random()}
            onChange={(e) => onSelectChange(e, item.id)}
          />
          <label
            className="custom-control-label"
            htmlFor={item.id + "pid-all"}
          ></label>
        </div>
      </DataTableRow>
      <DataTableRow noText>
        <Link
          to={`${process.env.PUBLIC_URL}/employees/${item.id}`}
          state={item}
        >
          <div className="user-card">
            <UserAvatar
              className="xs"
              text={
                item?.firstName?.charAt(0).toUpperCase() +
                item?.lastName?.charAt(0).toUpperCase()
              }
            ></UserAvatar>
            <div className="user-info">
              <span className="tb-lead">
                {item.firstName} {item.lastName}
              </span>
            </div>
          </div>
        </Link>
      </DataTableRow>
      <DataTableRow
        size="sm"
        text={formatDate(item?.createdAt, DATE_FORMATS.DATE_REGISTERED)}
      />
      <DataTableRow
        text={VACANCIES_ENUM[item?.positionType] ?? item?.positionType}
      />
      <DataTableRow noText>
        <span
          className={`tb-status text-${
            item?.status === "VIEWED" ? "success" : "danger"
          }`}
        >
          {item.status}
        </span>
      </DataTableRow>
      <DataTableRow className="nk-tb-col-tools" noText>
        <ul className="nk-tb-actions gx-1">
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-icon btn-trigger"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#suspend"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <Icon name="na"></Icon>
                      <span>Suspend User</span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#delete"
                      onClick={(ev) => {
                        ev.preventDefault();
                        handleEmployeeDelete(item.id);
                      }}
                    >
                      <Icon name="trash"></Icon>
                      <span>Delete</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </DataTableRow>
    </DataTableItem>
  );
};

export default EmployeesPageTableBodyItems;
