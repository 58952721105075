import React from "react";
import { Card } from "reactstrap";

import { Block, BlockBetween, BlockTitle } from "../../Component";
import ImagePlaceHolder from "../../image/ImagePlaceHolder";
import UpdatableText from "../../shared/UpdatabaleText";

const EmployeeInfoDocumentsBlock = ({ employee }) => {
  return (
    <Card className="card-inner">
      <Block>
        <BlockBetween>
          <BlockTitle tag="h5" className={"mb-3"}>
            Documents
          </BlockTitle>
        </BlockBetween>
        <div className="profile-ud-list">
          <div className="profile-ud-item d-flex w-15">
            <UpdatableText
              title={"Photo 3.5x4.5"}
              defaultValue={
                <ImagePlaceHolder
                  image={employee?.urls?.photo}
                  imageAlt={employee?.firstName}
                  imageName="Photo 3.5x4.5"
                />
              }
              href={employee?.urls?.photo}
            />
          </div>
          <div className="profile-ud-item d-flex w-15">
            <UpdatableText
              title={"Passport"}
              defaultValue={
                <ImagePlaceHolder
                  image={employee?.urls?.passport}
                  imageAlt={employee?.firstName}
                  imageName="Passport"
                />
              }
              href={employee?.urls?.passport}
            />
          </div>
          <div className="profile-ud-item w-50">
            <UpdatableText
              title={"Diploma, transcript and others"}
              defaultValue={
                <div className="d-flex justify-content-around w-80">
                  {employee?.urls.urls?.map((url, index) => (
                    <div
                      className="profile-ud-item d-flex align-items-center"
                      key={index}
                    >
                      <ImagePlaceHolder
                        image={url}
                        imageAlt={employee?.firstName}
                        imageName="Diploma, transcript and others"
                      />
                    </div>
                  ))}
                </div>
              }
            />
          </div>
        </div>
      </Block>
    </Card>
  );
};

export default EmployeeInfoDocumentsBlock;
