import React from "react";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import isNull from "lodash/isNull";
import { useSearchParams, useNavigate } from "react-router-dom";

import { Icon } from "../Component";

const PageSizeDropdownComponent = ({ pageSize, setPageSize }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleOnPageSizeChange = (event, pageSize) => {
    event.preventDefault();

    const isPageSizeQueryNull = isNull(searchParams.get("page-size"));

    setPageSize(pageSize);

    if (!isPageSizeQueryNull) {
      searchParams.set("page-size", pageSize);
      setSearchParams(searchParams);
    }
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        tag="a"
        className="btn btn-trigger btn-icon dropdown-toggle"
      >
        <Icon name="setting"></Icon>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-xs">
        <ul className="link-check">
          <li>
            <span>Show</span>
          </li>

          <li className={pageSize === 15 || isNull(pageSize) ? "active" : ""}>
            <DropdownItem
              tag="a"
              href="#dropdownitem"
              onClick={(event) => {
                handleOnPageSizeChange(event, 15);
              }}
            >
              15
            </DropdownItem>
          </li>
          <li className={pageSize === 20 ? "active" : ""}>
            <DropdownItem
              tag="a"
              href="#dropdownitem"
              onClick={(event) => {
                handleOnPageSizeChange(event, 20);
              }}
            >
              20
            </DropdownItem>
          </li>
        </ul>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default PageSizeDropdownComponent;
