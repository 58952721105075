import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull";

import {
  getEmployees,
  getEmployeeById,
  updateEmployeeStatus,
  removeEmployee,
} from "../repositories/employeeRepository";

export default {
  getEmployees: async ({ currentPage, pageSize }) => {
    const pageNumber =
      isUndefined(currentPage) || isNull(currentPage) ? 1 : currentPage;
    const itemsPerPage =
      isUndefined(pageSize) || isNull(pageSize) ? 20 : pageSize;

    return await getEmployees({
      "current-page": pageNumber,
      "page-size": itemsPerPage,
    });
  },

  getEmployeeById: async (id) => await getEmployeeById(id),

  updateEmployeeStatus: async (status, body) =>
    await updateEmployeeStatus(status, body),

  removeEmployee: async (id) => await removeEmployee(id),
};
