import React from "react";

const InputHint = ({ hint }) => {
  return (
    <div className="form-text-hint">
      <span className="overline-title">{hint}</span>
    </div>
  );
};

export default InputHint;
