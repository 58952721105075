import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Card } from "reactstrap";
import { remove } from "lodash";

import {
  BlockBetween,
  BlockTitle,
  InputSwitch,
  RSelect,
} from "../../Component";
import UpdatableText from "../../shared/UpdatabaleText";
import DropZoneInput from "../../input/dropzone/DropzoneInput";
import SelectedItemComponent from "../../shared/SelectemItemComponent";
import BlockUpdateButton from "../../button/BlockUpdateButton";

import { useBeManualFetcher } from "../../../hooks/useBeManualFetcher";
import categoryService from "../../../services/categoryService";
import newsService from "../../../services/newsService";
import {
  IMAGE_KEYS,
  LANGUAGE_KEYS,
  LINK_TYPES,
} from "../../../utilities/constants";
import { appendToFormData } from "../../../utilities/formDataUtils";
import { imageToFileConvert } from "../../../utilities/imageUtils";
import EventEmitter, { EVENT_TOPICS } from "../../../utilities/eventUtils";

const NewsAdditionalInfoBlock = ({ news }) => {
  const { newsId } = useParams();
  const { handleSubmit } = useForm();
  const [onFetch, isLoading] = useBeManualFetcher();
  const [onLinkFetch, isLinkFetchLoading] = useBeManualFetcher();

  const [isAtTop, setIsAtTop] = useState(news?.isAtTop);
  const [isLatestNews, setIsLatestNews] = useState(
    news?.latestNews?.isLatestNews
  );
  const [latestNewsImage, setLatestNewsImage] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [linkedCategories, setLinkedCategories] = useState([]);
  const [hasEditClicked, setHasEditClicked] = useState(false);
  const [sequenceNumber, setSequenceNumber] = useState(news?.sequenceNumber);
  function handleOnEditClicked() {
    setHasEditClicked((prev) => !prev);
  }

  const handleCloseEdit = (response) => {
    EventEmitter.emit(EVENT_TOPICS.ON_NEWS_UPDATED, {
      updatedNews: response,
    });

    setHasEditClicked(false);
  };

  async function onFormSubmit() {
    const requestBody = {
      latestNews: {
        isLatestNews,
      },
      isAtTop,
      sequenceNumber,
    };

    await onFetch({
      action: () => newsService.updateNews(newsId, requestBody),
      onLoad: async (response) => {
        if (latestNewsImage?.[0]) {
          let newFormData = new FormData();

          if (latestNewsImage?.[0]) {
            appendToFormData(newFormData, {
              newsId,
              latestNews: imageToFileConvert(
                latestNewsImage?.[0],
                IMAGE_KEYS.COVER_IMAGE
              ),
            });
          }

          await newsService.uploadNewsFiles(newFormData).then(() => {
            handleCloseEdit(response);
          });
        } else {
          handleCloseEdit(response);
        }
      },
      successMessage: "News updated successfully",
    });
  }

  useEffect(async () => {
    return await onFetch({
      action: async () =>
        await categoryService.getCategories({
          pageSize: 100,
        }),
      onLoad: (response) => {
        setCategories(response?.data);

        if (news?.categoryIds.length > 0) {
          const copiedLinkedCategories = [...news?.categoryIds];
          const newsLinkedCategories = copiedLinkedCategories?.map(
            (category) => {
              const categoryTitle = response?.data?.find(
                (item) => item?.id === category
              )?.title?.[LANGUAGE_KEYS.EN];

              return { id: category, title: categoryTitle };
            }
          );

          setLinkedCategories(newsLinkedCategories);
        }
      },
    });
  }, []);

  async function handleLinkCategoryToNews(
    selectedCategoryId,
    selectedCategoryTitle
  ) {
    await onLinkFetch({
      action: () =>
        newsService.linkNewsToCategories(newsId, LINK_TYPES.LINK, [
          selectedCategoryId,
        ]),
      onLoad: () => {
        setLinkedCategories((prev) => {
          const copiedCategories = [...prev];

          copiedCategories.push({
            id: selectedCategoryId,
            title: selectedCategoryTitle,
          });

          return copiedCategories;
        });

        setSelectedCategory({ label: "Select a category" });
      },
    });
  }

  async function handleUnlinkCategory(e, categoryId) {
    e.preventDefault();

    return await onFetch({
      action: () =>
        categoryService.linkCategoryToNews(categoryId, LINK_TYPES.UNLINK, [
          newsId,
        ]),
      onLoad: () => {
        const copiedLinkedCategories = [...linkedCategories];

        remove(
          copiedLinkedCategories,
          (category) => category?.id === categoryId
        );
        setLinkedCategories(copiedLinkedCategories);
      },
      fetchingItemId: categoryId,
    });
  }

  const categoryOptions = useMemo(
    () =>
      categories
        .filter(
          (category) =>
            category?.id !==
            linkedCategories?.find((element) => element.id === category?.id)?.id
        )
        .map((category) => {
          return {
            id: category?.id,
            value: category?.id,
            label: category?.title?.[LANGUAGE_KEYS.EN],
          };
        }),
    [categories, linkedCategories]
  );

  return (
    <Card className="card-inner">
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <BlockBetween className={"align-items-center"}>
          <BlockTitle tag="h5">Display Information</BlockTitle>
          <BlockUpdateButton
            hasEditClicked={hasEditClicked}
            handleOnEditClicked={handleOnEditClicked}
          />
        </BlockBetween>

        <div className="profile-ud-list position-relative">
          <div className="profile-ud-item">
            <UpdatableText
              title="Is Latest News"
              defaultValue={
                <InputSwitch
                  id="latestNews"
                  style={{ paddingLeft: "4.75rem" }}
                  checked={isLatestNews}
                />
              }
              editableValueComponent={
                <InputSwitch
                  id="latestNews"
                  style={{ paddingLeft: "4.75rem" }}
                  onClick={() => setIsLatestNews((prev) => !prev)}
                  checked={isLatestNews}
                />
              }
              onEditClicked={hasEditClicked}
              isWider
            />
            <UpdatableText
              title="Is At Top"
              defaultValue={
                <InputSwitch
                  id="isAtTop"
                  style={{ paddingLeft: "4.75rem" }}
                  checked={isAtTop}
                />
              }
              editableValueComponent={
                <InputSwitch
                  id="isAtTop"
                  style={{ paddingLeft: "4.75rem" }}
                  onClick={() => setIsAtTop((prev) => !prev)}
                  checked={isAtTop}
                />
              }
              onEditClicked={hasEditClicked}
              isWider
            />
            <UpdatableText
              title="Sequence Number"
              onEditClicked={hasEditClicked}
              isWider
              onChange={(e) => setSequenceNumber(e.target.value)}
              value={sequenceNumber}
              name="sequenceNumber"
            />
          </div>
          <div className="profile-ud-item">
            <UpdatableText
              title="Is Latest News"
              defaultValue={
                news?.latestNews?.url ? (
                  <img
                    className="w-40 h-40"
                    src={news?.latestNews?.url}
                    alt={news?.latestNews?.url}
                  />
                ) : (
                  "No Image"
                )
              }
              editableValueComponent={
                <DropZoneInput
                  files={latestNewsImage}
                  setFiles={setLatestNewsImage}
                />
              }
              onEditClicked={hasEditClicked}
            />
          </div>

          <div className="profile-ud-item">
            <UpdatableText
              title={"Linked Categories"}
              defaultValue={
                <>
                  {linkedCategories?.map((category) => (
                    <SelectedItemComponent
                      key={category?.id}
                      title={category?.title}
                      isLoading={isLoading?.id == category?.id}
                      icon={false}
                    />
                  ))}
                </>
              }
              editableValueComponent={
                <>
                  <RSelect
                    options={categoryOptions}
                    onChange={async (e) =>
                      await handleLinkCategoryToNews(e.id, e.label)
                    }
                    value={selectedCategory}
                    isLoading={isLinkFetchLoading}
                  />
                  <div className="mt-2">
                    {linkedCategories?.map((category) => (
                      <SelectedItemComponent
                        key={category?.id}
                        title={category?.title}
                        onClick={(e) => handleUnlinkCategory(e, category?.id)}
                        isLoading={isLoading?.id == category?.id}
                      />
                    ))}
                  </div>
                </>
              }
              onEditClicked={hasEditClicked}
            />
          </div>
        </div>
      </form>
    </Card>
  );
};

export default NewsAdditionalInfoBlock;
