import React, { useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { DataTableItem, DataTableRow, Icon } from "../Component";
import ConfirmationModal from "../modals/ConfirmationModal";

import categoryService from "../../services/categoryService";
import { useNewsCategoryPageContext } from "../../hooks/useNewsCategoryPageContext";
import { useBeManualFetcher } from "../../hooks/useBeManualFetcher";
import { LANGUAGE_KEYS } from "../../utilities/constants";

function NewsCategoryPageTableBodyItem({ id, title }) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [onFetch] = useBeManualFetcher();
  const { categoriesDispatch } = useNewsCategoryPageContext();

  const handleDeleteClick = () => setShowDeleteModal(true);

  const handleCategoryDelete = async (id) => {
    await onFetch({
      action: () => categoryService.removeCategory(id),
      onLoad: () => {
        categoriesDispatch({ type: "CATEGORY_DELETE", id: id });
        setShowDeleteModal(false);
      },
      successMessage: "Category successfully deleted",
    });
  };
  return (
    <DataTableItem>
      <DataTableRow text={title?.[LANGUAGE_KEYS.EN]} />
      <DataTableRow text={title?.[LANGUAGE_KEYS.RU]} />
      <DataTableRow text={title?.[LANGUAGE_KEYS.UZ]} />
      <DataTableRow className="nk-tb-col-tools" noText>
        <ul className="nk-tb-actions gx-1">
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-icon btn-trigger"
              >
                <Icon name="more-h" />
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#delete"
                      onClick={handleDeleteClick}
                    >
                      <Icon name="trash"></Icon>
                      <span>Delete</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </DataTableRow>
      <ConfirmationModal
        showModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={() => handleCategoryDelete(id)}
        confirmColor="danger"
        confirmTitle="Delete"
      />
    </DataTableItem>
  );
}

export default NewsCategoryPageTableBodyItem;
