import React, { useState } from "react";
import remove from "lodash/remove";

import { EmployeePageContext } from "../contexts/employeePageContext";
import { useEventListener } from "../hooks/useEventListener";
import { EVENT_TOPICS } from "../utilities/eventUtils";

const EmployeePageContextProvider = ({ children }) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState();
  const [totalPages, setTotalPages] = useState();
  const [employees, setEmployees] = useState();

  useEventListener(
    EVENT_TOPICS.ON_EMPLOYEE_DELETED,
    ({ id }) => {
      const copiedData = [...employees];
      remove(copiedData, (item) => item.id === id);

      setEmployees(copiedData);
    },
    [employees]
  );

  return (
    <EmployeePageContext.Provider
      value={{
        employees,
        setEmployees,
        currentPage,
        setCurrentPage,
        totalPages,
        setTotalPages,
        totalRecords,
        setTotalRecords,
        pageSize,
        setPageSize,
      }}
    >
      {children}
    </EmployeePageContext.Provider>
  );
};

export default EmployeePageContextProvider;
