import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Card } from "reactstrap";

import BlockUpdateButton from "../../button/BlockUpdateButton";
import { Block, BlockBetween, BlockTitle } from "../../Component";
import ImagePlaceHolder from "../../image/ImagePlaceHolder";
import DropZoneInput from "../../input/dropzone/DropzoneInput";
import UpdatableText from "../../shared/UpdatabaleText";

import { useBeManualFetcher } from "../../../hooks/useBeManualFetcher";
import { useStudentPageContext } from "../../../hooks/useStudentPageContext";
import studentService from "../../../services/studentService";
import EventEmitter, { EVENT_TOPICS } from "../../../utilities/eventUtils";
import { appendToFormData } from "../../../utilities/formDataUtils";

const StudentDocumentsBlock = () => {
  const { id } = useParams();
  const { student } = useStudentPageContext();
  const { handleSubmit } = useForm();
  const [onFetch] = useBeManualFetcher();

  const [personalPhoto, setPersonalPhoto] = useState([]);
  const [passportPhoto, setPassportPhoto] = useState([]);
  const [IELTSPhoto, setIELTSPhoto] = useState([]);
  const [restPhotos, setRestPhotos] = useState([]);

  const [hasEditClicked, setHasEditClicked] = useState(false);

  function handleOnEditClicked() {
    setHasEditClicked((prev) => !prev);
  }

  async function onFormSubmit() {
    const newFormData = new FormData();

    appendToFormData(newFormData, {
      passport: passportPhoto?.[0],
      photo: personalPhoto?.[0],
    });

    if (IELTSPhoto?.[0]) {
      appendToFormData(newFormData, {
        hasEnglishLanguageProficiencyEvidence: true,
        englishProficiencyEvidenceCertificate: IELTSPhoto?.[0],
      });
    }

    if (restPhotos.length > 0) {
      const mergedFiles = [...restPhotos];

      mergedFiles.forEach((file) => {
        newFormData.append(`files`, file);
      });
    }
    
    return await onFetch({
      action: () => studentService.updateStudent(id, newFormData),
      onLoad: (response) => {
        EventEmitter.emit(EVENT_TOPICS.ON_STUDENT_UPDATED, { response });
        handleOnEditClicked();
      },
      successMessage: "Student fields updated successfully",
    });
  }

  console.log(student?.englishDegree?.englishProficiencyEvidenceCertificateUrl);

  return (
    <Card className="card-inner">
      <Block>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <BlockBetween>
            <BlockTitle tag="h5">Documents</BlockTitle>
            <BlockUpdateButton
              hasEditClicked={hasEditClicked}
              handleOnEditClicked={handleOnEditClicked}
            />
          </BlockBetween>
          <div className="profile-ud-list">
            <div className="profile-ud-item d-flex w-15">
              <UpdatableText
                title={hasEditClicked ? "Photo 3.5x4.5" : null}
                defaultValue={
                  <ImagePlaceHolder
                    image={student?.photoUrl}
                    imageAlt={student?.firstName}
                    imageName="Photo 3.5x4.5"
                  />
                }
                href={student?.photoUrl}
                editableValueComponent={
                  <DropZoneInput
                    files={personalPhoto}
                    setFiles={setPersonalPhoto}
                  />
                }
                onEditClicked={hasEditClicked}
              />
            </div>
            <div className="profile-ud-item d-flex w-15">
              <UpdatableText
                title={hasEditClicked ? "Passport" : null}
                defaultValue={
                  <ImagePlaceHolder
                    image={student?.passportUrl}
                    imageAlt={student?.firstName}
                    imageName="Passport"
                  />
                }
                href={student?.passportUrl}
                editableValueComponent={
                  <DropZoneInput
                    files={passportPhoto}
                    setFiles={setPassportPhoto}
                  />
                }
                onEditClicked={hasEditClicked}
              />
            </div>
            <div className="profile-ud-item d-flex w-20">
              <UpdatableText
                title={hasEditClicked ? "English Prof. Evidence" : null}
                href={
                  student?.englishDegree
                    ?.englishProficiencyEvidenceCertificateUrl
                }
                defaultValue={
                  <>
                    {student?.englishDegree
                      ?.englishProficiencyEvidenceCertificateUrl && (
                      <ImagePlaceHolder
                        image={
                          student?.englishDegree
                            ?.englishProficiencyEvidenceCertificateUrl
                        }
                        imageAlt={student?.firstName}
                        imageName="English Prof. Evidence"
                      />
                    )}
                  </>
                }
                editableValueComponent={
                  <DropZoneInput files={IELTSPhoto} setFiles={setIELTSPhoto} />
                }
                onEditClicked={hasEditClicked}
              />
            </div>
            <div className="profile-ud-item w-50">
              <UpdatableText
                title={hasEditClicked ? "Diploma, transcript and others" : null}
                defaultValue={
                  <div className="d-flex justify-content-around w-80">
                    {student?.urls?.map((url, index) => (
                      <div
                        className="profile-ud-item d-flex align-items-center"
                        key={index}
                      >
                        <ImagePlaceHolder
                          image={url}
                          imageAlt={student?.firstName}
                          imageName="Diploma, transcript and others"
                        />
                      </div>
                    ))}
                  </div>
                }
                editableValueComponent={
                  <DropZoneInput
                    files={restPhotos}
                    setFiles={setRestPhotos}
                    multiple
                  />
                }
                onEditClicked={hasEditClicked}
              />
            </div>
          </div>
        </form>
      </Block>
    </Card>
  );
};

export default StudentDocumentsBlock;
