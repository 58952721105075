import React from "react";
import { Card } from "reactstrap";

import { Block, BlockHead, BlockTitle } from "../../Component";

const EmployeeInfoSummaryBlock = ({ user }) => {
  return (
    <Card className="card-inner">
      <Block>
        <BlockHead>
          <BlockTitle tag="h5">Summary</BlockTitle>
        </BlockHead>
        <div className="profile-ud-list">
          <div className="profile-ud-item w-100">
            <div className="profile-ud mb-2">
              <span className="profile-ud-label">
                Why do you want to work for PTU?
              </span>
              <span className="profile-ud-value">
                {user?.comment?.reasonAboutWorkingForPtu}
              </span>
            </div>
            <div className="profile-ud mb-2">
              <span className="profile-ud-label">
                Why do you think you are qualified for this position?
              </span>
              <span className="profile-ud-value">
                {user?.comment?.positionOfQualified}
              </span>
            </div>
            <div className="profile-ud mb-2">
              <span className="profile-ud-label">
                What is your vision of this position?
              </span>
              <span className="profile-ud-value">
                {user?.comment?.visionOfPosition}
              </span>
            </div>
          </div>
        </div>
      </Block>
    </Card>
  );
};

export default EmployeeInfoSummaryBlock;
