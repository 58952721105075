import { config } from "../configs/repositoryConfig";
import backendHttpRequest from "./backendHttpRequest";

export const authSignIn = async ({ email, password }) => {
  const body = { username: email, password };

  return await backendHttpRequest({
    resourceObject: config.backendApiSettings.resources.getAccessToken,
    body,
  });
};

export const authRefreshAccessToken = async (refreshToken) => {
  const body = { refreshToken };

  return await backendHttpRequest({
    resourceObject: config.backendApiSettings.resources.refreshAccessToken,
    body,
  });
};
