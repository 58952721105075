import React from "react";

import Button from "./Button";

const BlockUpdateButton = ({ hasEditClicked, handleOnEditClicked }) => {
  return (
    <div className="mb-3">
      {!hasEditClicked ? (
        <Button
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            handleOnEditClicked((prev) => !prev);
          }}
          iconName="edit-fill"
          title="Update Block"
        />
      ) : (
        <>
          <Button
            color="success"
            type="submit"
            className={"mr-2"}
            iconName="check"
            title={"Confirm"}
          />
          <Button
            color="danger"
            onClick={(e) => {
              e.preventDefault();
              handleOnEditClicked((prev) => !prev);
            }}
            iconName="cross"
            title={"Cancel"}
          />
        </>
      )}
    </div>
  );
};

export default BlockUpdateButton;
