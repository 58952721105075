import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalFooter } from "reactstrap";

import { Button } from "../Component";

const ConfirmationModal = ({
  showModal,
  onClose,
  onConfirm,
  modalTitle = "Are you sure?",
  confirmTitle = "Confirm",
  confirmColor = "primary",
  confirmIcon = "check",
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <Modal size="sm" isOpen={showModal}>
      <ModalHeader
        toggle={onClose}
        close={<Button className="close" onClick={onClose} iconName="cross" />}
      >
        {t(modalTitle)}
      </ModalHeader>
      <ModalFooter className="d-flex justify-content-between">
        <Button
          size="lg"
          color="white border-light"
          onClick={onClose}
          title={t("Close")}
        />
        <Button
          size="lg"
          color={confirmColor}
          onClick={onConfirm}
          title={t(confirmTitle)}
          disabled={isLoading}
          isLoading={isLoading}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
