import {
  AlignmentType,
  Document,
  HeadingLevel,
  Paragraph,
  TextRun,
  Table,
  TableCell,
  TableRow,
  WidthType,
} from "docx";
import { DATE_FORMATS, LANGUAGE_LEVELS, VACANCIES_ENUM } from "./constants";
import { formatDate } from "./dateTimeUtils";

export const DocumentCreator = (user) => {
  const document = new Document({
    sections: [
      {
        children: [
          new Paragraph({
            text: "CURRICULUM VITAE",
            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.CENTER,
          }),
          new Table({
            columnWidths: [3005, 6005],
            rows: [
              //personal details
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 6005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Personal Details",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                    columnSpan: 2,
                  }),
                ],
              }),
              //Name
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 3005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Name",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 5505,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: `${user.firstName} ${user.lastName}`,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              //position
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 3005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Position and Area",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 5505,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: `${VACANCIES_ENUM[user?.positionType]}`,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              //responsibilities
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 3005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Designated Responsibilities",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 5505,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "",
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              //dob
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 3005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Date of Birth",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 5505,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: `${formatDate(
                              user?.dateOfBirth,
                              DATE_FORMATS.DATE_REGISTERED
                            )}`,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              //email address
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 3005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Email Address",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 5505,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: `${user?.email}`,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),

              //phoneNumber
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 3005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Phone Number",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 5505,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: `${user?.phoneNumber}`,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),

              //heardFrom
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 3005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Heard From",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 5505,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: `${user?.hearAboutVacancy}`,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),

              //expected salary
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 3005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Expected Salary",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 5505,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: `${user?.expectedSalary.amount} ${user?.expectedSalary.currency}`,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),

              //adress
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 6005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Address",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                    columnSpan: 2,
                  }),
                ],
              }),

              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 3005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Country",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 5505,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: `${user?.address?.country ?? "-"}`,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 3005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "City",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 5505,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: `${user?.address?.city ?? "-"}`,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 3005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "District",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 5505,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: `${user?.address?.district ?? "-"}`,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 3005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Street",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 5505,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: `${user?.address?.street ?? "-"}`,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 3005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Home Number",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 5505,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: `${user?.address?.instruction ?? "-"}`,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 3005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Postal Code",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 5505,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: `${user?.address?.postalCode ?? "-"}`,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),

              //education
              ...(user?.educations
                ?.map((education, index) => {
                  const arr = [];

                  if (index === 0) {
                    arr.push(
                      new TableRow({
                        children: [
                          new TableCell({
                            width: {
                              size: 6005,
                              type: WidthType.DXA,
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "Education",
                                    bold: true,
                                  }),
                                ],
                              }),
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "(Higher Education Only) Repeat for each Qualification",
                                  }),
                                ],
                              }),
                            ],
                            columnSpan: 2,
                          }),
                        ],
                      })
                    );
                  }

                  //Education name inst
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Institution",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${education?.nameOfInstitution}`,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  //Education Dates
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Dates",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${formatDate(
                                    new Date(education?.startDate),
                                    DATE_FORMATS.DATE_REGISTERED
                                  )} - ${
                                    education?.endDate
                                      ? formatDate(
                                          new Date(education?.endDate),
                                          DATE_FORMATS.DATE_REGISTERED
                                        )
                                      : "Present"
                                  }`,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  //Education Qualification
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Degrees/diplomas/certificates",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${education?.qualification}`,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  //Empty Space
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: ``,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  return arr;
                })
                ?.reduce((prev, curr) => prev?.concat(curr), []) ?? []),

              //Employment
              ...(user?.employmentPeriods
                ?.map((employment, index) => {
                  const arr = [];

                  if (index === 0) {
                    arr.push(
                      new TableRow({
                        children: [
                          new TableCell({
                            width: {
                              size: 6005,
                              type: WidthType.DXA,
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "Work expirience",
                                    bold: true,
                                  }),
                                ],
                              }),
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "Last 20 years",
                                  }),
                                ],
                              }),
                            ],
                            columnSpan: 2,
                          }),
                        ],
                      })
                    );
                  }

                  //Employment Dates
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Dates",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${formatDate(
                                    new Date(employment?.startDate),
                                    DATE_FORMATS.DATE_REGISTERED
                                  )} - ${
                                    employment?.endDate
                                      ? formatDate(
                                          new Date(employment?.endDate),
                                          DATE_FORMATS.DATE_REGISTERED
                                        )
                                      : "Present"
                                  }`,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  //Employment company
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Company",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${employment?.organizationName}`,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  //Employment Position
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Position",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${employment?.positionInOrganization}`,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  //Employment Description
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Description",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${employment?.summaryAboutResponsibilities}`,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  //Employment Supervisor
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Supervisor",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${employment?.supervisorPhoneNumber?.name} ${employment?.supervisorPhoneNumber?.phoneNumber}`,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  //Empty Space
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: ``,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  return arr;
                })
                ?.reduce((prev, curr) => prev?.concat(curr), []) ?? []),

              //Trainings
              ...(user?.practicalTrainings
                ?.map((education, index) => {
                  const arr = [];

                  if (index === 0) {
                    arr.push(
                      new TableRow({
                        children: [
                          new TableCell({
                            width: {
                              size: 6005,
                              type: WidthType.DXA,
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "Practical Trainings",
                                    bold: true,
                                  }),
                                ],
                              }),
                            ],
                            columnSpan: 2,
                          }),
                        ],
                      })
                    );
                  }

                  //Training name inst
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Institution",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${education?.nameOfInstitution}`,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  //Training Dates
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Dates",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${formatDate(
                                    new Date(education?.startDate),
                                    DATE_FORMATS.DATE_REGISTERED
                                  )} - ${
                                    education?.endDate
                                      ? formatDate(
                                          new Date(education?.endDate),
                                          DATE_FORMATS.DATE_REGISTERED
                                        )
                                      : "Present"
                                  }`,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  //Training Qualification
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Qualification",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${education?.qualificationOrOutcomes}`,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  //Empty Space
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: ``,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  return arr;
                })
                ?.reduce((prev, curr) => prev?.concat(curr), []) ?? []),

              //Langugages
              ...(user?.languages
                ?.map((language, index) => {
                  const arr = [];

                  if (index === 0) {
                    arr.push(
                      new TableRow({
                        children: [
                          new TableCell({
                            width: {
                              size: 6005,
                              type: WidthType.DXA,
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "Languages",
                                    bold: true,
                                  }),
                                ],
                              }),
                            ],
                            columnSpan: 2,
                          }),
                        ],
                      })
                    );
                  }

                  //language name
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Language",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${language?.language}`,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  //language level
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Level",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${
                                    LANGUAGE_LEVELS[language?.languageLevel]
                                  }`,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  //Empty Space
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: ``,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  return arr;
                })
                ?.reduce((prev, curr) => prev?.concat(curr), []) ?? []),

              //Referees
              ...(user?.referees
                ?.map((referee, index) => {
                  const arr = [];

                  if (index === 0) {
                    arr.push(
                      new TableRow({
                        children: [
                          new TableCell({
                            width: {
                              size: 6005,
                              type: WidthType.DXA,
                            },
                            children: [
                              new Paragraph({
                                children: [
                                  new TextRun({
                                    text: "Referees",
                                    bold: true,
                                  }),
                                ],
                              }),
                            ],
                            columnSpan: 2,
                          }),
                        ],
                      })
                    );
                  }

                  //Referee name
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Name and Surname",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${referee?.fullName ?? ""}`,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  //Referee Organization
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Organization",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${referee?.organization ?? ""}`,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  //Referee Position
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Position",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${referee?.position ?? ""}`,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  //Referee Phone Number
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Phone Number",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${referee?.phoneNumber ?? ""}`,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  //Referee Email
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "Email",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${referee?.email ?? ""}`,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  //Empty Space
                  arr.push(
                    new TableRow({
                      children: [
                        new TableCell({
                          width: {
                            size: 3005,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "",
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          width: {
                            size: 5505,
                            type: WidthType.DXA,
                          },
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: ``,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    })
                  );

                  return arr;
                })
                ?.reduce((prev, curr) => prev?.concat(curr), []) ?? []),

              //reasonForWorkingForPTU title
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 6005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Why do you want to work for PTU?",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                    columnSpan: 2,
                  }),
                ],
              }),

              //reasonForWorkingForPTU
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 6005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: `${
                              user?.comment?.reasonAboutWorkingForPtu ?? ""
                            }`,
                          }),
                        ],
                      }),
                    ],
                    columnSpan: 2,
                  }),
                ],
              }),

              //positionQualification title
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 6005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Why do you think you are qualified for this position?",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                    columnSpan: 2,
                  }),
                ],
              }),

              //reasonForWorkingForPTU
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 6005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: `${user?.comment?.positionOfQualified ?? ""}`,
                          }),
                        ],
                      }),
                    ],
                    columnSpan: 2,
                  }),
                ],
              }),

              //visionAboutPosition title
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 6005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "What is your vision of this position?",
                            bold: true,
                          }),
                        ],
                      }),
                    ],
                    columnSpan: 2,
                  }),
                ],
              }),

              //visionAboutPosition
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 6005,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: `${user?.comment?.visionOfPosition ?? ""}`,
                          }),
                        ],
                      }),
                    ],
                    columnSpan: 2,
                  }),
                ],
              }),
            ],
          }),
        ],
      },
    ],
  });

  return document;
};
