import { USER_ROLES } from "../../utilities/constants";

const menu = [
  {
    icon: "user-alt",
    text: "Students",
    link: "/students",
    allowedRoles: [
      USER_ROLES.ADMIN_STUDENT,
      USER_ROLES.ADMIN,
      USER_ROLES.DEVELOPER,
    ],
  },
  {
    icon: "account-setting-alt",
    text: "Employees",
    link: "/employees",
    allowedRoles: [
      USER_ROLES.ADMIN_EMPLOYEE,
      USER_ROLES.ADMIN,
      USER_ROLES.DEVELOPER,
    ],
  },
  {
    icon: "template",
    text: "News",
    allowedRoles: [
      USER_ROLES.ADMIN_MARKETING,
      USER_ROLES.ADMIN,
      USER_ROLES.DEVELOPER,
    ],
    subMenu: [
      {
        text: "News",
        link: "/news",
      },
      {
        text: "Category",
        link: "/news-category",
      },
    ],
  },
];
export default menu;
