import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { DataTableItem } from "../Component";

const DraggableItemHOC = ({ children, id, index }) => {
  return (
    <Draggable draggableId={id} index={index} key={id}>
      {(provided) => (
        <DataTableItem key={id} provided={provided}>
          {children}
        </DataTableItem>
      )}
    </Draggable>
  );
};

export default DraggableItemHOC;
