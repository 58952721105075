import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import { Packer } from "docx";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";

import EmployeeInfoMainBlock from "../../components/employeePage/emplopyeeDetailsPage/EmployeeInfoMainBlock";
import EmployeeInfoEmploymentBlock from "../../components/employeePage/emplopyeeDetailsPage/EmployeeInfoEmploymentBlock";
import EmployeeInfoEducationBlock from "../../components/employeePage/emplopyeeDetailsPage/EmployeeInfoEducationBlock";
import EmployeeInfoTrainingBlock from "../../components/employeePage/emplopyeeDetailsPage/EmployeeInfoTrainingBlock";
import EmployeeInfoLanguageBlock from "../../components/employeePage/emplopyeeDetailsPage/EmployeeInfoLanguageBlock";
import EmployeeInfoRefereeBlock from "../../components/employeePage/emplopyeeDetailsPage/EmployeeInfoRefereeBlock";
import EmployeeInfoSummaryBlock from "../../components/employeePage/emplopyeeDetailsPage/EmployeeInfoSummaryBlock";
import DetailsPageHeader from "../../components/shared/DetailsPageHeader";
import EmployeeInfoAddressBlock from "../../components/employeePage/emplopyeeDetailsPage/EmployeeInfoAddressBlock";

import { DocumentCreator } from "../../utilities/docxGeneratorUtils";
import { useStatusContext } from "../../hooks/useStatusContext";
import employeeService from "../../services/employeeService";
import EmployeeInfoDocumentsBlock from "../../components/employeePage/emplopyeeDetailsPage/EmployeeInfoDocumentsBlock";

const EmployeeDetailsPage = () => {
  const { state } = useLocation();
  const { onSuccessStatus } = useStatusContext();

  const [employee] = useState(state);

  const hanleGenerateCV = () => {
    const doc = DocumentCreator(employee);

    Packer.toBlob(doc).then(async (blob) => {
      saveAs(blob, `${employee?.firstName}${employee?.lastName}-CV.docx`);
      await employeeService.updateEmployeeStatus("VIEWED", [employee?.id]);

      onSuccessStatus({ title: "Document created successfully" });
    });
  };

  return (
    <>
      <Head title="Employee Details"></Head>
      <Content>
        <DetailsPageHeader
          title={"Employee -"}
          name={`${employee?.firstName} ${employee?.lastName}`}
          createdDate={employee?.createdAt}
          position={employee?.positionType}
          goBackPath={"/employees"}
          onGenerateClick={hanleGenerateCV}
        />
        <EmployeeInfoMainBlock user={employee} />
        {employee?.address?.city?.length > 0 && (
          <EmployeeInfoAddressBlock user={employee} />
        )}
        {employee?.employmentPeriods?.length > 0 && (
          <EmployeeInfoEmploymentBlock user={employee} />
        )}
        {employee?.educations?.length > 0 && (
          <EmployeeInfoEducationBlock user={employee} />
        )}
        {employee?.practicalTrainings?.length > 0 && (
          <EmployeeInfoTrainingBlock user={employee} />
        )}
        {employee?.languages?.length > 0 && (
          <EmployeeInfoLanguageBlock user={employee} />
        )}
        {employee?.referees?.length > 0 && (
          <EmployeeInfoRefereeBlock user={employee} />
        )}
        <EmployeeInfoSummaryBlock user={employee} />
        <EmployeeInfoDocumentsBlock employee={employee} />
      </Content>
    </>
  );
};

export default EmployeeDetailsPage;
