import React, { useCallback } from "react";

import { useSearchParams } from "react-router-dom";

import MainPageTableHeader from "../../layout/body/MainPageTableHeader";
import MainPageTableBody from "../../layout/body/MainPageTableBody";
import MainPageHOC from "../../layout/hoc/MainPageHOC";

import { PaginationComponent } from "../../components/Component";
import FetcherComponent from "../../components/shared/FetcherComponent";
import MainPageHeader from "../../components/shared/MainPageHeader";

import { useNewsCategoryPageContext } from "../../hooks/useNewsCategoryPageContext";
import NewsCategoryPageTableBody from "../../components/newsCategoryPage/NewsCategoryPageTableBody";
import categoryService from "../../services/categoryService";

const NewsCategoryPage = () => {
  const { categories, categoriesDispatch } = useNewsCategoryPageContext();

  const [searchParams] = useSearchParams();
  const currentPageQueryString = searchParams.get("current-page");
  const pageSizeQueryString = searchParams.get("page-size");

  const fetchCategories = useCallback(
    async () =>
      await categoryService.getCategories({
        currentPage: currentPageQueryString,
        pageSize: pageSizeQueryString,
      }),
    [currentPageQueryString, pageSizeQueryString]
  );

  const handleOnLoadFetchedCategories = useCallback(
    (response) => {
      categoriesDispatch({ type: "FETCH", response });
    },
    [categoriesDispatch]
  );

  return (
    <MainPageHOC
      headerTitle={"News Category"}
      pageHeader={
        <MainPageHeader
          totalRecords={categories?.totalRecords}
          pageName="News Category"
          redirectTo={"create"}
          showAddButton={true}
        />
      }
      tableHeader={
        <MainPageTableHeader
          pageSize={categories?.pageSize}
          setPageSize={categories?.setPageSize}
        />
      }
      tableBody={
        <FetcherComponent
          action={fetchCategories}
          onLoad={handleOnLoadFetchedCategories}
        >
          <NewsCategoryPageTableBody pageName={"news category"} />
          <PaginationComponent
            currentPageNumber={categories?.currentPage}
            totalPages={categories?.totalPages}
            totalRecords={categories?.totalRecords}
            status={pageSizeQueryString}
          />
        </FetcherComponent>
      }
    />
  );
};

export default NewsCategoryPage;
