import isEmpty from "lodash/isEmpty";

export const convertToQueryString = (filterObj) =>
  filterObj
    ? Object.keys(filterObj)
        .map((key) => `${key}=${filterObj[key]}`)
        .join("&")
    : null;

export const convertQuerySearchToObject = (queryString) => {
  var search = queryString.substring(1);

  if (isEmpty(queryString)) {
    return {};
  }

  return JSON.parse(
    '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    (key, value) => (key === "" ? value : decodeURIComponent(value))
  );
};
