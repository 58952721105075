import React from "react";
import { Card } from "reactstrap";

import { Block, BlockHead, BlockTitle } from "../../Component";

const EmployeeInfoAddressBlock = ({ user }) => {
  return (
    <Card className="card-inner">
      <Block className={undefined} size={undefined}>
        <BlockHead className={undefined} size={undefined} wide={undefined}>
          <BlockTitle tag="h5" className={undefined} page={undefined}>
            Address Information
          </BlockTitle>
        </BlockHead>
        <div className="profile-ud-list">
          <div className="profile-ud-item w-45 mr-5">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Country</span>
              <span className="profile-ud-value">
                {user?.address?.country ?? "-"}
              </span>
            </div>
            <div className="profile-ud wider">
              <span className="profile-ud-label">City</span>
              <span className="profile-ud-value">{user?.address.city}</span>
            </div>
            <div className="profile-ud wider">
              <span className="profile-ud-label">District</span>
              <span className="profile-ud-value">{user?.address.district}</span>
            </div>
          </div>
          <div className="profile-ud-item w-45">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Street</span>
              <span className="profile-ud-value">{user?.address.street}</span>
            </div>
            <div className="profile-ud wider">
              <span className="profile-ud-label">Home Number</span>
              <span className="profile-ud-value">
                {user?.address.instruction}
              </span>
            </div>
            <div className="profile-ud wider">
              <span className="profile-ud-label">Postal Code</span>
              <span className="profile-ud-value">
                {user?.address.postalCode}
              </span>
            </div>
          </div>
        </div>
      </Block>
    </Card>
  );
};

export default EmployeeInfoAddressBlock;
