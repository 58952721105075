import React from "react";
import { useLocation, Navigate } from "react-router-dom";

import SpinnerComponent from "../components/shared/SpinnerComponent";

import { useAuthContext } from "../hooks/useAuthContext";
import { getAccessToken } from "../services/authService";

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { loading } = useAuthContext();

  if (loading) return <SpinnerComponent />;

  const token = getAccessToken();

  if (!token) {
    return <Navigate to="/auth-login" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
