import backendHttpRequest from "./backendHttpRequest";
import { config } from "../configs/repositoryConfig";
import { convertToQueryString } from "../utilities/httpUtils";
import { replaceAllStringByKeys } from "../utilities/stringUtils";

export const getNews = async (filteredObject) => {
  const queryString = convertToQueryString(filteredObject);
  const resourceObject = {
    ...config.backendApiSettings.resources.getNews,
  };
  const endpoint = resourceObject.endpoint;
  resourceObject.endpoint = `${endpoint}${queryString ? `?${queryString}` : ""
    }`;

  const apiNews = await backendHttpRequest({ resourceObject });

  return apiNews;
};

export const getNewsById = async (id) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.getNewsById,
  };
  const endpoint = resourceObject.endpoint;

  resourceObject.endpoint = endpoint.replace(":id", id);

  const apiNews = await backendHttpRequest({ resourceObject });

  return apiNews;
};

export const createNews = async (body) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.createNews,
  };


  const apiNews = await backendHttpRequest({
    resourceObject,
    body,
  });

  return apiNews;
};

export const updateNews = async (id, body) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.updateNews,
  };

  const endpoint = resourceObject.endpoint;
  resourceObject.endpoint = endpoint.replace(":id", id);

  const updatedNews = await backendHttpRequest({
    resourceObject,
    body,
  });

  return updatedNews;
};

export const uploadNewsFiles = async (formData) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.uploadNewsFiles,
  };

  const apiNews = await backendHttpRequest({
    resourceObject,
    body: formData,
    isFormData: true,
  });

  return apiNews;
};

export const removeNews = async (id) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.deleteNews,
  };
  const endpoint = resourceObject.endpoint;

  resourceObject.endpoint = endpoint.replace(":id", id);

  await backendHttpRequest({ resourceObject });

  return id;
};

export const updateNewsStatus = async (id, status, body) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.updateNewsStatus,
  };

  resourceObject.endpoint = replaceAllStringByKeys(
    resourceObject.endpoint,
    [":id", ":status"],
    [id, status]
  );

  const updatedNews = await backendHttpRequest({
    resourceObject,
    body,
  });

  return updatedNews;
};

export async function linkNewsToCategories(id, type, body) {
  const resourceObject = {
    ...config.backendApiSettings.resources.linkNewsToCategories,
  };

  resourceObject.endpoint = replaceAllStringByKeys(
    resourceObject.endpoint,
    [":id", ":type"],
    [id, type]
  );

  return await backendHttpRequest({
    resourceObject,
    body,
  });
}

export async function changeNewsSequence(id, sequenceNumber) {
  const resourceObject = {
    ...config.backendApiSettings.resources.changeNewsSequence,
  };

  resourceObject.endpoint = replaceAllStringByKeys(
    resourceObject.endpoint,
    [":id", ":sequenceNumber"],
    [id, sequenceNumber]
  );

  return await backendHttpRequest({
    resourceObject,
  });
}
