export function swapPosition(result, data, setData) {
  const { source, destination } = result;
  const copiedDatas = [...data];
  let item;

  if (!destination) return;

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  )
    return;

  if (source.droppableId === "Droppable") {
    item = copiedDatas[source.index];
    copiedDatas.splice(source.index, 1);
  }

  if (destination.droppableId === "Droppable") {
    copiedDatas.splice(destination.index, 0, item);
  }

  setData(copiedDatas);
}
