import React from "react";
import { Card } from "reactstrap";

import { Block, BlockHead, BlockTitle } from "../../Component";

import { formatDate } from "../../../utilities/dateTimeUtils";
import { DATE_FORMATS } from "../../../utilities/constants";

const EmployeeInfoEmploymentBlock = ({ user }) => {
  return (
    <Card className="card-inner">
      <Block>
        <BlockHead>
          <BlockTitle tag="h5">Employment History</BlockTitle>
        </BlockHead>
        {user?.employmentPeriods?.map((employment, index) => (
          <React.Fragment key={index}>
            {index > 0 && <div className="nk-divider divider md"></div>}
            <div className="profile-ud-list">
              <div className="profile-ud-item w-45 mr-5">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Position</span>
                  <span className="profile-ud-value">
                    {employment?.positionInOrganization}
                  </span>
                </div>
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Organization</span>
                  <span className="profile-ud-value">
                    {employment?.organizationName}
                  </span>
                </div>
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Summary about duties</span>
                  <span className="profile-ud-value">
                    {employment?.summaryAboutResponsibilities}
                  </span>
                </div>
              </div>
              <div className="profile-ud-item w-45">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Duration</span>
                  <span className="profile-ud-value">
                    {formatDate(
                      new Date(employment?.startDate),
                      DATE_FORMATS.DATE_REGISTERED
                    )}{" "}
                    -{" "}
                    {employment?.endDate
                      ? formatDate(
                          new Date(employment?.endDate),
                          DATE_FORMATS.DATE_REGISTERED
                        )
                      : "Present"}
                  </span>
                </div>
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Supervisor Name</span>
                  <span className="profile-ud-value">
                    {employment?.supervisorPhoneNumber?.name}
                  </span>
                </div>
                <div className="profile-ud wider">
                  <span className="profile-ud-label">
                    Supervisor Phone Number
                  </span>
                  <span className="profile-ud-value">
                    {employment?.supervisorPhoneNumber?.phoneNumber}
                  </span>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </Block>
    </Card>
  );
};

export default EmployeeInfoEmploymentBlock;
