import React from "react";
import { Card } from "reactstrap";

import { Block, BlockHead, BlockTitle, UserAvatar } from "../../Component";

import { DATE_FORMATS } from "../../../utilities/constants";
import { formatDate } from "../../../utilities/dateTimeUtils";

const EmployeeInfoMainBlock = ({ user }) => {
  return (
    <Card className="card-inner">
      <Block>
        <BlockHead>
          <BlockTitle tag="h5">Personal Information</BlockTitle>
        </BlockHead>
        <div className="profile-ud-list">
          <div className="profile-ud-item d-flex align-items-center w-15">
            <div className="profile-ud wider">
              <UserAvatar
                className="xl"
                text={`${user?.firstName ? user?.firstName?.charAt(0) : ""}${
                  user?.lastName ? user?.lastName?.charAt(0) : ""
                }`}
              />
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">First Name</span>
              <span className="profile-ud-value">{user?.firstName}</span>
            </div>
            <div className="profile-ud wider">
              <span className="profile-ud-label">Last Name</span>
              <span className="profile-ud-value">{user?.lastName}</span>
            </div>
            <div className="profile-ud wider">
              <span className="profile-ud-label">Date of Birth</span>
              <span className="profile-ud-value">
                {formatDate(
                  new Date(user?.dateOfBirth),
                  DATE_FORMATS.DATE_REGISTERED
                )}
              </span>
            </div>
            <div className="profile-ud wider">
              <span className="profile-ud-label">Heard from</span>
              <span className="profile-ud-value">{user?.hearAboutVacancy}</span>
            </div>
          </div>

          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Phone Number</span>
              <span className="profile-ud-value">{user?.phoneNumber}</span>
            </div>
            <div className="profile-ud wider">
              <span className="profile-ud-label">Email Address</span>
              <span className="profile-ud-value">{user?.email}</span>
            </div>
            <div className="profile-ud wider">
              <span className="profile-ud-label">Expected Salary</span>
              <span className="profile-ud-value">
                {user?.expectedSalary?.amount} {user?.expectedSalary?.currency}
              </span>
            </div>
          </div>
        </div>
      </Block>

      {/* <Block>
        <BlockHead className="nk-block-head-line">
          <BlockTitle tag="h6" className="overline-title text-base">
            Additional Information
          </BlockTitle>
        </BlockHead>
        <div className="profile-ud-list">
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Joining Date</span>
              <span className="profile-ud-value">08-16-2018 09:04PM</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Reg Method</span>
              <span className="profile-ud-value">Email</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Country</span>
              <span className="profile-ud-value">{user.country}</span>
            </div>
          </div>
          <div className="profile-ud-item">
            <div className="profile-ud wider">
              <span className="profile-ud-label">Nationality</span>
              <span className="profile-ud-value">{user.country}</span>
            </div>
          </div>
        </div>
      </Block> */}
      {/* 
      <div className="nk-divider divider md"></div> */}
    </Card>
  );
};

export default EmployeeInfoMainBlock;
