import { useSlate } from "slate-react";
import { TEXT_ALIGN_TYPES } from "../../../utilities/constants";
import { isBlockActive, toggleBlock } from "../../../utilities/richEditorUtils";
import { Icon } from "../../Component";
import EditorButton from "./EditorButton";

const EditorBlockButton = ({ format, icon, text }) => {
  const editor = useSlate();

  const renderContent = icon ? <Icon name={icon} /> : <p>{text}</p>;

  return (
    <EditorButton
      active={isBlockActive(
        editor,
        format,
        Object.values(TEXT_ALIGN_TYPES).includes(format) ? "align" : "type"
      )}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {renderContent}
    </EditorButton>
  );
};

export default EditorBlockButton;
