import React from "react";
import classNames from "classnames";

import "./Badge.css";

const Badge = ({ text, color, className, fontColor }) => {
  const badgeClass = classNames({
    [`bg-${color}`]: true,
    [`${className}`]: className,
  });

  return (
    <div
      className={`${badgeClass} badge-wrapper no-underline font-color-${
        fontColor ? fontColor : "white"
      }`}
    >
      {text}
    </div>
  );
};

export default Badge;
