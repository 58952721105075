import React from "react";
import { Block, DataTable } from "../../components/Component";
import Content from "../content/Content";
import Head from "../head/Head";

const MainPageHOC = ({
  headerTitle = "Students",
  pageHeader,
  tableHeader,
  tableBody,
}) => {
  return (
    <>
      <Head title={headerTitle}></Head>
      <Content>
        {pageHeader}
        <Block>
          <DataTable className="card-stretch" bordered={false}>
            {tableHeader}
            {tableBody}
          </DataTable>
        </Block>
      </Content>
    </>
  );
};

export default MainPageHOC;
