import React, { useReducer, useState } from "react";
import remove from "lodash/remove";

import { NewsCategoryPageContext } from "../contexts/newsCategoryPageContext";

const initialState = {
  totalRecords: 0,
  currentPage: 1,
  pageSize: 0,
  totalPages: 0,
  data: [],
};

function reducer(state, action) {
  switch (action.type) {
    case "FETCH":
      return {
        ...state,
        ...action.response,
      };

    case "CATEGORY_DELETE":
      const categoryData = [...state?.data];

      remove(categoryData, (item) => item.id === action.id);

      return {
        ...state,
        data: categoryData,
      };

    default:
      return initialState;
  }
}

function NewsCategoryPageContextProvider({ children }) {
  const [categories, categoriesDispatch] = useReducer(reducer, initialState);

  return (
    <NewsCategoryPageContext.Provider
      value={{
        categories,
        categoriesDispatch,
      }}
    >
      {children}
    </NewsCategoryPageContext.Provider>
  );
}

export default NewsCategoryPageContextProvider;
