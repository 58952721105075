import React, { useCallback } from "react";
import { useParams } from "react-router-dom";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import DetailsPageHeader from "../../components/shared/DetailsPageHeader";
import NewsMainInfoBlock from "../../components/newsPage/newsDetailsPage/NewsMainInfoBlock";
import NewsImagesBlock from "../../components/newsPage/newsDetailsPage/NewsImagesBlock";

import {
  IMAGE_KEYS,
  LANGUAGE_KEYS,
} from "../../utilities/constants";
import { unixDateConvertor } from "../../utilities/dateTimeUtils";
import Fetcher from "../../components/shared/FetcherComponent";
import newsService from "../../services/newsService";
import NewsAdditionalInfoBlock from "../../components/newsPage/newsDetailsPage/NewsAdditionalInfoBlock";
import { useNewsPageContext } from "../../hooks/useNewsPageContext";

const NewsDetailsPage = () => {
  const { singleNews, setSingleNews } = useNewsPageContext();
  const { newsId } = useParams();

  const fetchNews = useCallback(
    async () => await newsService.getNewsById(newsId),
    [newsId]
  );

  const handleOnLoadFetchedNews = useCallback(
    (response) => {
      setSingleNews(response);
    },
    [setSingleNews]
  );

  console.log(unixDateConvertor(singleNews?.createdAt));

  return (
    <>
      <Head title="News Details" />
      <Content>
        <Fetcher action={fetchNews} onLoad={handleOnLoadFetchedNews}>
          <DetailsPageHeader
            title={"News -"}
            name={singleNews?.title?.[LANGUAGE_KEYS.EN]}
            createdDate={singleNews?.createdAt}
            goBackPath={"/news"}
          />
          <NewsMainInfoBlock news={singleNews} />
          <NewsAdditionalInfoBlock news={singleNews} />
          <NewsImagesBlock
            enImage={
              singleNews?.imageUrls?.[LANGUAGE_KEYS.EN]?.[
                IMAGE_KEYS.COVER_IMAGE_GET
              ]
            }
            ruImage={
              singleNews?.imageUrls?.[LANGUAGE_KEYS.RU]?.[
                IMAGE_KEYS.COVER_IMAGE_GET
              ]
            }
            uzImage={
              singleNews?.imageUrls?.[LANGUAGE_KEYS.UZ]?.[
                IMAGE_KEYS.COVER_IMAGE_GET
              ]
            }
          />
        </Fetcher>
      </Content>
    </>
  );
};

export default NewsDetailsPage;
