import { useState } from "react";

import { BE_ERROR_MESSAGES } from "../utilities/constants";
import { useStatusContext } from "./useStatusContext";

export const useBeManualFetcher = (hasErrorMessage = true) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { onSuccessStatus, onErrorStatus } = useStatusContext();

  const onFetch = ({
    action,
    onLoad,
    actionWhenError = () => {},
    successMessage = false,
    fetchingItemId,
  }) => {
    setIsLoading(
      fetchingItemId ? { isLoading: true, id: fetchingItemId } : true
    );

    action()
      .then((data) => {
        onLoad(data);
        successMessage &&
          onSuccessStatus({
            title: "Operation is succeeded",
            message: successMessage,
          });
      })
      .catch((err) => {
        const errorMessage = err?.beErrorCode
          ? BE_ERROR_MESSAGES[err.beErrorCode]
          : "Something went wrong";

        {
          hasErrorMessage &&
            onErrorStatus({
              title: "Unsuccessful operation",
              message: errorMessage,
            });
        }

        actionWhenError();
        setHasError(true);
      })
      .finally(() => setIsLoading(false));
  };

  return [onFetch, isLoading, hasError];
};
