import React from "react";

import { Button } from "../Component";

const SelectedItemComponent = ({ title, onClick, isLoading, icon = true }) => {
  return (
    <Button
      title={title}
      iconSecond
      iconName={icon && "cross"}
      className={"p-1 mr-1 mb-1"}
      color={"white border-light"}
      onClick={onClick}
      isLoading={isLoading}
    />
  );
};
export default SelectedItemComponent;
