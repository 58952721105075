import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Card } from "reactstrap";
import { useBeManualFetcher } from "../../../hooks/useBeManualFetcher";
import { useStudentPageContext } from "../../../hooks/useStudentPageContext";
import studentService from "../../../services/studentService";
import EventEmitter, { EVENT_TOPICS } from "../../../utilities/eventUtils";
import { appendToFormData } from "../../../utilities/formDataUtils";
import BlockUpdateButton from "../../button/BlockUpdateButton";

import { Block, BlockBetween, BlockTitle } from "../../Component";
import UpdatableText from "../../shared/UpdatabaleText";

const StudentAddressInfoBlock = () => {
  const { id } = useParams();
  const { register, handleSubmit } = useForm();
  const [onFetch] = useBeManualFetcher();
  const { student } = useStudentPageContext();

  const [hasEditClicked, setHasEditClicked] = useState(false);

  function handleOnEditClicked() {
    setHasEditClicked((prev) => !prev);
  }

  async function onFormSubmit(form) {
    const { city, district, street, instruction, postalCode } = form;
    const newFormData = new FormData();

    appendToFormData(newFormData, {
      city,
      district,
      street,
      instruction,
      postalCode,
    });

    return await onFetch({
      action: () => studentService.updateStudent(id, newFormData),
      onLoad: (response) => {
        EventEmitter.emit(EVENT_TOPICS.ON_STUDENT_UPDATED, { response });
        handleOnEditClicked();
      },
      successMessage: "Student fields updated successfully",
    });
  }

  return (
    <Card className="card-inner">
      <Block>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <BlockBetween>
            <BlockTitle tag="h5">Address Information</BlockTitle>
            <BlockUpdateButton
              hasEditClicked={hasEditClicked}
              handleOnEditClicked={handleOnEditClicked}
            />
          </BlockBetween>
          <div className="profile-ud-list">
            <div className="profile-ud-item  mr-5">
              <UpdatableText
                title="City"
                defaultValue={student?.address?.city}
                name={"city"}
                register={register}
                onEditClicked={hasEditClicked}
                isWider
              />
              <UpdatableText
                title="District"
                defaultValue={student?.address?.district}
                name={"district"}
                register={register}
                onEditClicked={hasEditClicked}
                isWider
              />
              <UpdatableText
                title="Street"
                defaultValue={student?.address?.street}
                name={"street"}
                register={register}
                onEditClicked={hasEditClicked}
                isWider
              />
            </div>
            <div className="profile-ud-item">
              <UpdatableText
                title="Home Number"
                defaultValue={student?.address?.instruction}
                name={"instruction"}
                register={register}
                onEditClicked={hasEditClicked}
                isWider
              />
              <UpdatableText
                title="Postal Code"
                defaultValue={student?.address?.postalCode}
                name={"postalCode"}
                register={register}
                onEditClicked={hasEditClicked}
                isWider
              />
            </div>
          </div>
        </form>
      </Block>
    </Card>
  );
};

export default StudentAddressInfoBlock;
