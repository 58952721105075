import React, { useCallback, useMemo } from "react";
import isHotkey from "is-hotkey";
import isUrl from "is-url";
import {
  Editable,
  withReact,
  Slate,
  useSelected,
  useSlate,
  useFocused,
  useSlateStatic,
} from "slate-react";
import {
  createEditor,
  Editor,
  Transforms,
  Range,
  Element as SlateElement,
  Descendant,
} from "slate";
import { withHistory } from "slate-history";
import { Card } from "reactstrap";

import EditorToolbar from "./EditorToolBar";
import EditorMarkButton from "./EditorMarkButton";
import EditorBlockButton from "./EditorBlockButton";

import { HOTKEYS } from "../../../utilities/constants";
import { toggleMark } from "../../../utilities/richEditorUtils";
import EditorButton from "./EditorButton";
import { Icon } from "../../Component";
import { css } from "@emotion/css";

const RichTextEditor = ({ value, setValue }) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(
    () => withInlines(withHistory(withReact(createEditor()))),
    []
  );

  return (
    <Card className="card-inner">
      <Slate
        editor={editor}
        value={value}
        onChange={(value) => {
          const isAstChange = editor.operations.some(
            (op) => "set_selection" !== op.type
          );
          if (isAstChange) {
            setValue(value);
          }
        }}
      >
        <EditorToolbar>
          <EditorMarkButton format="bold" icon="bold" />
          <EditorMarkButton format="italic" icon="italic" />
          <EditorMarkButton format="underline" icon="underline" />
          <EditorMarkButton format="code" icon="code" />
          <EditorBlockButton format="heading-one" text={"H1"} />
          <EditorBlockButton format="heading-two" text={"H2"} />
          <EditorBlockButton format="heading-three" text={"H3"} />
          <EditorBlockButton format="heading-four" text={"H4"} />
          <EditorBlockButton format="heading-five" text={"H5"} />
          <EditorBlockButton format="heading-six" text={"H6"} />
          <EditorBlockButton format="block-quote" icon="quote-left" />
          <AddLinkButton />
          <RemoveLinkButton />
          <EditorBlockButton format="numbered-list" icon="list-ol" />
          <EditorBlockButton format="bulleted-list" icon="list" />
          <EditorBlockButton format="left" icon="align-left" />
          <EditorBlockButton format="center" icon="align-center" />
          <EditorBlockButton format="right" icon="align-right" />
          <EditorBlockButton format="justify" icon="align-justify" />
        </EditorToolbar>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Enter some text…"
          spellCheck
          autoFocus
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
        />
      </Slate>
    </Card>
  );
};

const withInlines = (editor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) =>
    ["link"].includes(element.type) || isInline(element);

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export const Element = ({ attributes, children, element }) => {
  const style = { textAlign: element.align };
  const props = { attributes, children, element };
  switch (element.type) {
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "heading-three":
      return (
        <h3 style={style} {...attributes}>
          {children}
        </h3>
      );
    case "heading-four":
      return (
        <h4 style={style} {...attributes}>
          {children}
        </h4>
      );
    case "heading-five":
      return (
        <h5 style={style} {...attributes}>
          {children}
        </h5>
      );
    case "heading-six":
      return (
        <h6 style={style} {...attributes}>
          {children}
        </h6>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "link":
      return <LinkComponent {...props} />;

    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  });
  return !!link;
};

const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  });
};

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const AddLinkButton = () => {
  const editor = useSlate();
  return (
    <EditorButton
      active={isLinkActive(editor)}
      onMouseDown={(event) => {
        event.preventDefault();
        const url = window.prompt("Enter the URL of the link:");
        if (!url) return;
        insertLink(editor, url);
      }}
    >
      <Icon name={"link"}></Icon>
    </EditorButton>
  );
};

const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    className={css`
      font-size: 0;
    `}
  >
    ${String.fromCodePoint(160)}
  </span>
);

const LinkComponent = ({ attributes, children, element }) => {
  const selected = useSelected();
  return (
    <a
      {...attributes}
      href={element.url}
      style={{ textDecoration: "underline" }}
      className={
        selected
          ? css`
              box-shadow: 0 0 0 3px #ddd;
            `
          : ""
      }
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </a>
  );
};

const RemoveLinkButton = () => {
  const editor = useSlate();

  return (
    <EditorButton
      active={isLinkActive(editor)}
      onMouseDown={(event) => {
        if (isLinkActive(editor)) {
          unwrapLink(editor);
        }
      }}
    >
      <Icon name={"unlink"}></Icon>
    </EditorButton>
  );
};

export default RichTextEditor;
