// exports will be kept in alphebetic order
export const BE_ERROR_MESSAGES = {
  1000: "Something went wrong",
  1001: "Address not found",
  1004: "User not found",
  1005: "Given Phone Number is invalid",
  1006: "Email you are trying to submit already exists in the SRMS",
  1007: "The given phone number already exists in the system",
  1008: "Authentication error",
  1009: "User authentication failed",
  1010: "You're not authenticated, please signin/signup",
  1011: "Given phone number not found",
  1012: "Not authorized to access this resource",
  1013: "Email already exists",
  1014: "Not authorized to access this resource",
  1015: "Not authorized to access this resource",
  1016: "Address request cannot be empty",
  1017: "Cannot have multiple default addresses",
  1018: "Cannot set default address",
  1019: "Address not found",
  1020: "Missing IDs to batch delete records",
  1021: "Given email not found",
  1022: "Cannot delete",
  1032: "Price and weight of the shipment cannot be empty",
};

export const DATE_FORMATS = {
  DATE_REGISTERED: "DD.MM.YYYY",
  DATE_TIME: "MMMM Do YYYY, HH:mm",
  DATE_TIME_FORMAL: "YYYY-MM-DDTHH:mm",
  DATE_WITHOUT_YEAR: "MMMM DD, h:mm:ss",
  DATE_WITHOUT_DAY: "YYYY-MM",
  DATE_ONLY: "YYYY-MM-DD",
  TIME: "hh:mm",
  DAY: "dddd",
};

export const DEFAULT_CURRENCY = {
  UZS: "UZS",
};

export const FACULTIES_ENUM = {
  FOUNDATION_CERTIFICATE_PHARMACEUTICAL_SCIENCE:
    "FOUNDATION_CERTIFICATE_PHARMACEUTICAL_SCIENCE",
  FOUNDATION_CERTIFICATE_BIOSCIENCES: "FOUNDATION_CERTIFICATE_BIOSCIENCES",
  FOUNDATION_CERTIFICATE_SCIENCES: "FOUNDATION_CERTIFICATE_SCIENCES",
  PRE_UNIVERSITY: "PRE_UNIVERSITY",
  PRE_FOUNDATION: "PRE_FOUNDATION",
};

export const FACULTIES_ENUM_READABLE = {
  FOUNDATION_CERTIFICATE_PHARMACEUTICAL_SCIENCE:
    "Foundation Certificate in Pharmaceutical Science",
  FOUNDATION_CERTIFICATE_BIOSCIENCES: "Foundation Certificate in Biosciences",
  FOUNDATION_CERTIFICATE_SCIENCES: "Foundation Certificate in Sciences",
  PRE_UNIVERSITY: "Pre-university",
  PRE_FOUNDATION: "Pre-Foundation",
};

export const FETCH_STATE = {
  IDLE: "idle",
  LOADING: "loading",
  ERROR: "error",
  COMPLETE: "complete",
};

export const FILE_EXTENSIONS = {
  DOC: "doc",
  DOCX: "docx",
  PDF: "pdf",
  JPEG: "jpeg",
  JPG: "jpg",
  PNG: "png",
  SVG: "svg",
  WEBP: "webp",
};

export const ERROR_MESSAGES = {
  "auth/network-request-failed":
    "It looks like you are not online, please connect to internet",
  "auth/email-already-in-use": "Current Email is already in use",
  "auth/wrong-password": "Email or Password is incorrect",
  "auth/invalid-api-key": "Partner Not Found",
};

export const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

export const IMAGE_EXTENSIONS = {
  PNG: ".png",
  JPG: ".jpg",
  JPEG: ".jpeg",
  SVG: ".svg",
  SVG_XML: ".svg+xml",
  WEBP: ".webp",
};

export const IMAGE_KEYS = {
  PRODUCT_IMAGE_GET: "size1500x1500",
  COVER_IMAGE_GET: "size1920x1080",
  LOGO_IMAGE_GET: "size500x500",
  COURIER_IMAGE_GET: "size1000x1000",
  COVER_IMAGE: "1920x1080",
  LOGO_IMAGE: "500x500",
  PRODUCT_IMAGE: "1500x1500",
  COURIER_IMAGE: "1000x1000",
  PRODUCT_IMAGE_MINIMUM_SIZE: 1500,
  LOGO_IMAGE_SIZE: 500,
  COVER_IMAGE_WIDTH_SIZE: 1920,
  COVER_IMAGE_HEIGHT_SIZE: 1080,
  COURIER_IMAGE_MINIMUM_SIZE: 1000,
};

export const LANGUAGE_KEYS = {
  UZ: "uzUZ",
  EN: "enUS",
  RU: "ruRU",
};

export const LANGUAGE_LEVELS = {
  ELEMENTARY: "Elementary Proficiency",
  LIMITED_WORKING: "Limited Working Proficiency",
  PROFESSIONAL: "Professional Working Proficiency",
  FULL_PROFESSIONAL: "Full Professional Proficiency",
  NATIVE: "Native or Bilingual Proficiency",
};

export const LANGUAGE_WRAPPERS = {
  EN_NAME_WRAPPER: `multiLanguageWrapper[name][${LANGUAGE_KEYS.EN}]`,
  RU_NAME_WRAPPER: `multiLanguageWrapper[name][${LANGUAGE_KEYS.RU}]`,
  UZ_NAME_WRAPPER: `multiLanguageWrapper[name][${LANGUAGE_KEYS.UZ}]`,
  EN_DESCRIPTION_WRAPPER: `multiLanguageWrapper[description][${LANGUAGE_KEYS.EN}]`,
  RU_DESCRIPTION_WRAPPER: `multiLanguageWrapper[description][${LANGUAGE_KEYS.RU}]`,
  UZ_DESCRIPTION_WRAPPER: `multiLanguageWrapper[description][${LANGUAGE_KEYS.UZ}]`,
  EN_TITLE_WRAPPER: `multiLanguageWrapper[title][${LANGUAGE_KEYS.EN}]`,
  RU_TITLE_WRAPPER: `multiLanguageWrapper[title][${LANGUAGE_KEYS.RU}]`,
  UZ_TITLE_WRAPPER: `multiLanguageWrapper[title][${LANGUAGE_KEYS.UZ}]`,
};

export const LINK_TYPES = {
  LINK: "LINK",
  UNLINK: "UNLINK",
};
export const LIST_TYPES = {
  OL: "numbered-list",
  UL: "bulleted-list",
};

export const SCHOLARSHIP_TYPES_ENUM = {
  TUITION_FEE: "TUITION_FEE",
  SCHOLARSHIP: "SCHOLARSHIP",
};

export const SCHOLARSHIP_TYPES_ENUM_READABLE = {
  TUITION_FEE: "Tuition fee based",
  SCHOLARSHIP: "Scholarship",
};

export const STUDENT_STATUSES = {
  SUBMITTED_APPLICATION: "SUBMITTED_APPLICATION",
  DOCUMENT_VERIFICATION: "DOCUMENT_VERIFICATION",
  OFFER_SENT_TO_APPLICATION: "OFFER_SENT_TO_APPLICATION",
  APPLICATION_CONDITIONAL_OFFER: "APPLICATION_CONDITIONAL_OFFER",
  REQUESTED_FOR_DOCUMENTS: "REQUESTED_FOR_DOCUMENTS",
  APPLICATION_UNCONDITIONAL_OFFER: "APPLICATION_UNCONDITIONAL_OFFER",
  TUITION_FEE_PAID: "TUITION_FEE_PAID",
  ENROLLED: "ENROLLED",
  REJECTED: "REJECTED",
};

export const STUDENT_READABLE_STATUSES = {
  SUBMITTED_APPLICATION: "Application Submitted",
  DOCUMENT_VERIFICATION: "Document Verification",
  OFFER_SENT_TO_APPLICATION: "Offer Sent to Partners",
  APPLICATION_CONDITIONAL_OFFER: "Conditional Offer",
  REQUESTED_FOR_DOCUMENTS: "Request for Docs",
  APPLICATION_UNCONDITIONAL_OFFER: "Unconditional Offer",
  TUITION_FEE_PAID: "Tuition Fee Paid",
  ENROLLED: "Enrolled",
  REJECTED: "Rejected",
};

export const STUDENT_STEPPER_STATUSES = {
  SUBMITTED_APPLICATION: "SUBMITTED_APPLICATION",
  DOCUMENT_VERIFICATION: "DOCUMENT_VERIFICATION",
  OFFER_SENT_TO_APPLICATION: "OFFER_SENT_TO_APPLICATION",
  APPLICATION_CONDITIONAL_OFFER: "APPLICATION_CONDITIONAL_OFFER",
  REQUESTED_FOR_DOCUMENTS: "REQUESTED_FOR_DOCUMENTS",
  APPLICATION_UNCONDITIONAL_OFFER: "APPLICATION_UNCONDITIONAL_OFFER",
  TUITION_FEE_PAID: "TUITION_FEE_PAID",
  ENROLLED: "ENROLLED",
};

export const STUDENT_STEPPER_READABLE_STATUSES = {
  SUBMITTED_APPLICATION: "Application Submitted",
  DOCUMENT_VERIFICATION: "Document Verification",
  OFFER_SENT_TO_APPLICATION: "Offer Sent to Partners",
  APPLICATION_CONDITIONAL_OFFER: "Conditional Offer",
  REQUESTED_FOR_DOCUMENTS: "Request for Docs",
  APPLICATION_UNCONDITIONAL_OFFER: "Unconditional Offer",
  TUITION_FEE_PAID: "Tuition Fee Paid",
  ENROLLED: "Enrolled",
};

export const STUDENT_STATUSES_STEPPER_MAP = {
  SUBMITTED_APPLICATION: 1,
  DOCUMENT_VERIFICATION: 2,
  OFFER_SENT_TO_APPLICATION: 3,
  APPLICATION_CONDITIONAL_OFFER: 4,
  REQUESTED_FOR_DOCUMENTS: 5,
  APPLICATION_UNCONDITIONAL_OFFER: 6,
  TUITION_FEE_PAID: 7,
  ENROLLED: 8,
};

export const DOCUMENT_VERIFIED_STATUSES = {
  PASSPORT_VERIFIED: "PASSPORT_VERIFIED",
  PHOTO_VERIFIED: "PHOTO_VERIFIED",
  IELTS_CERTIFICATE_VERIFIED: "IELTS_CERTIFICATE_VERIFIED",
  SECONDARY_EDUCATION_CERTIFICATE_VERIFIED:
    "SECONDARY_EDUCATION_CERTIFICATE_VERIFIED",
};

export const TEXT_ALIGN_TYPES = {
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right",
  JUSTIFY: "justify",
};

export const USER_ROLES = {
  DEVELOPER: "DEVELOPER",
  ADMIN: "ADMIN",
  ADMIN_STUDENT: "ADMIN_STUDENT",
  ADMIN_EMPLOYEE: "ADMIN_EMPLOYEE",
  ADMIN_MARKETING: "ADMIN_MARKETING",
};

export const VACANCIES_ENUM = {
  MARKETING_AND_COMMUNICATION_OFFICE: "Chief Public Relations Officer",
  SENIOR_OFFICER_ON_ANALYTICS: "Senior Officer on Analytics",
  ACADEMIC_REGISTRAR: "Academic Registrar",
  LEGAL_ADVISER: "Legal Adviser",
  STUDENT_SUPPORT_AND_WELFARE: "Chief Officer on Student Welfare",
  ACADEMIC_STAFF: "Academic Staff Member",
  SYSTEM_ADMINISTRATOR: "System Administrator",
  CHIEF_UNDERGRADUATE_OFFICER: "Chief Undergraduate Officer",
  CHIEF_ADMISSION_OFFICER: "Chief Admission Officer",
  LEADING_ICT_DEVELOPER: "Leading ICT Developer",
  ASSISTANT_LECTURER: "Assistant Lecturer",
  LECTURER: "Lecturer",
  SENIOR_LECTURER: "Senior Lecturer",
  HEAD_OF_DEPARTMENT: "Head of Academic Department",
  SENIOR_MARKETING_OFFICER: "Senior Marketing Officer",
  LEADING_MARKETING_OFFICER: "Leading Marketing Officer",
  LEADING_OFFICER_ON_INTERNATIONAL_STUDENTS:
    "Leading Officer on International Students",
};
