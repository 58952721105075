import React from "react";
import { Card } from "reactstrap";

import { Block, BlockHead, BlockTitle } from "../../Component";

import {
  DATE_FORMATS,
  SCHOLARSHIP_TYPES_ENUM_READABLE,
} from "../../../utilities/constants";
import { formatDate } from "../../../utilities/dateTimeUtils";
import { useStudentPageContext } from "../../../hooks/useStudentPageContext";

const StudentInfoEducationBlock = () => {
  const { student } = useStudentPageContext();

  return (
    <Card className="card-inner">
      <Block>
        <BlockHead>
          <BlockTitle tag="h5">Educational Information</BlockTitle>
        </BlockHead>
        {student?.facultyType !== "PRE_UNIVERSITY" ? (
          <div className="profile-ud-list">
            <div className="profile-ud-item mr-5">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Scholarship type</span>
                <span className="profile-ud-value">
                  {SCHOLARSHIP_TYPES_ENUM_READABLE[student?.feeType]}
                </span>
              </div>
              <div className="profile-ud wider">
                <span className="profile-ud-label">English Test</span>
                <span className="profile-ud-value">
                  {student?.englishDegree?.hasEnglishLanguageProficiencyEvidence
                    ? "Yes"
                    : "No"}
                </span>
              </div>
            </div>

            {!student?.englishDegree?.hasEnglishLanguageProficiencyEvidence && (
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Planning</span>
                  <span className="profile-ud-value">
                    {student?.englishDegree?.englishLanguageProficiencyTest
                      ?.planning
                      ? "Yes"
                      : "No"}
                  </span>
                </div>
                {student?.englishDegree?.englishLanguageProficiencyTest
                  ?.planning && (
                  <div className="profile-ud wider">
                    <span className="profile-ud-label">Planning Date</span>
                    <span className="profile-ud-value">
                      {formatDate(
                        new Date(
                          student?.englishDegree?.englishLanguageProficiencyTest?.examDate
                        ),
                        DATE_FORMATS.DATE_REGISTERED
                      )}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="profile-ud-list">
            <div className="profile-ud-item mr-5">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Courses</span>
                <span className="profile-ud-value">
                  {student?.preUniversityCourseType?.map((course) => (
                    <div className="mr-1" key={course}>
                      {course}
                    </div>
                  ))}
                </span>
              </div>
            </div>
          </div>
        )}
      </Block>
    </Card>
  );
};

export default StudentInfoEducationBlock;
