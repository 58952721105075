import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Button,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../Component";
import { formatDate, unixDateConvertor } from "../../utilities/dateTimeUtils";
import {
  DATE_FORMATS,
  FACULTIES_ENUM_READABLE,
  SCHOLARSHIP_TYPES_ENUM_READABLE,
  VACANCIES_ENUM,
} from "../../utilities/constants";

const DetailsPageHeader = ({
  title,
  name,
  createdDate,
  faculty,
  position,
  goBackPath,
  onGenerateClick,
  scholarship,
  buttonsToRender,
  isLoading,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <BlockHead size="sm">
      <Button
        color="white bg-white"
        outline
        className="mb-3"
        onClick={(e) => {
          e.preventDefault();
          navigate(goBackPath);
        }}
        iconName="arrow-left"
        title="Back"
      />
      <BlockBetween>
        <BlockHeadContent>
          <BlockTitle tag="h3" page noText>
            {t(title)} <strong className="text-gray">{name}</strong>
          </BlockTitle>
          <BlockDes className="text-soft">
            <ul className="list-inline">
              {createdDate && (
                <li className="mr-5">
                  {t("Created On")}:{" "}
                  <h6>
                    {unixDateConvertor(createdDate, DATE_FORMATS.DATE_TIME)}
                  </h6>
                </li>
              )}
              {faculty && (
                <li className="mr-5">
                  {t("Faculty")}: <h6>{FACULTIES_ENUM_READABLE[faculty]}</h6>
                </li>
              )}
              {scholarship && (
                <li>
                  {t("Fee Type")}:{" "}
                  <h6>{SCHOLARSHIP_TYPES_ENUM_READABLE[scholarship]}</h6>
                </li>
              )}
              {position && (
                <li>
                  {t("Applied for")}: <h6>{VACANCIES_ENUM[position]}</h6>
                </li>
              )}
            </ul>
          </BlockDes>
        </BlockHeadContent>
        <BlockHeadContent>
          {title === "Employee -" && (
            <Button
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                onGenerateClick();
              }}
              iconName="file-download"
              title="Generate CV"
            />
          )}
        </BlockHeadContent>
        {buttonsToRender?.length > 0 ? (
          <BlockHeadContent>
            {buttonsToRender?.map((button, index) => {
              return (
                <React.Fragment key={index}>
                  {!button.hideCondition ? (
                    <Button
                      color={button.color}
                      className={button.className}
                      onClick={(e) => {
                        e.preventDefault();
                        button.onClick();
                      }}
                      iconName={button.icon}
                      title={button.title}
                      disabled={isLoading === button.title}
                      isLoading={isLoading === button.title}
                    />
                  ) : null}
                </React.Fragment>
              );
            })}
          </BlockHeadContent>
        ) : null}
      </BlockBetween>
    </BlockHead>
  );
};

export default DetailsPageHeader;
