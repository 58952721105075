import React, { useState } from "react";

import { Icon, Button } from "../../components/Component";
import PageSizeDropdownComponent from "../../components/pagination/PageSizeDropdownComponent";

const MainPageTableHeader = ({ pageSize, setPageSize }) => {
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");

  const toggle = () => setonSearch(!onSearch);

  return (
    <div className="card-inner position-relative card-tools-toggle">
      <div className="card-title-group">
        <div className="card-tools"></div>
        <div className="card-tools mr-n1">
          <ul className="btn-toolbar gx-1">
            <li>
              <a
                href="#search"
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle();
                }}
                className="btn btn-icon search-toggle toggle-search"
              >
                <Icon name="search"></Icon>
              </a>
            </li>
            <li className="btn-toolbar-sep"></li>
            <li>
              <div className="toggle-wrap">
                <Button
                  className={`btn-icon btn-trigger toggle ${
                    tablesm ? "active" : ""
                  }`}
                  onClick={() => updateTableSm(true)}
                  iconName="menu-right"
                />
                <div
                  className={`toggle-content ${
                    tablesm ? "content-active" : ""
                  }`}
                >
                  <ul className="btn-toolbar gx-1">
                    <li className="toggle-close">
                      <Button
                        className="btn-icon btn-trigger toggle"
                        onClick={() => updateTableSm(false)}
                        iconName="arrow-left"
                      />
                    </li>
                    <li>
                      <PageSizeDropdownComponent
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className={`card-search search-wrap ${!onSearch && "active"}`}>
        <div className="card-body">
          <div className="search-content">
            <Button
              className="search-back btn-icon toggle-search active"
              onClick={() => {
                setSearchText("");
                toggle();
              }}
              iconName="arrow-left"
            />
            <input
              type="text"
              className="border-transparent form-focus-none form-control"
              placeholder="Search by user or email"
            />
            <Button className="search-submit btn-icon" iconName="search" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPageTableHeader;
