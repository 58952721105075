import React from "react";
import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Card } from "reactstrap";
import { STUDENT_STATUSES_STEPPER_MAP } from "../../../utilities/constants";
import { getKeyByValue } from "../../../utilities/objectUtils";

const StudentStepperComponent = ({ steps, onClick, hasCardWrapper = true }) => {
  const [searchParams] = useSearchParams();

  const activeStatus = searchParams.get("status");
  const cancelledStatus = searchParams.get("cancelledStatus");

  const Wrapper = hasCardWrapper ? Card : "div";

  const studentStatusMapper = {
    SUBMITTED_APPLICATION: 0,
    DOCUMENT_VERIFICATION: 1,
    OFFER_SENT_TO_APPLICATION: 2,
    APPLICATION_CONDITIONAL_OFFER: 3,
    REQUESTED_FOR_DOCUMENTS: 4,
    APPLICATION_UNCONDITIONAL_OFFER: 5,
    TUITION_FEE_PAID: 6,
    ENROLLED: 7,
  };

  const isStatusFailed = (index) => {
    return index === studentStatusMapper[cancelledStatus];
  };

  return (
    <Wrapper className="card-inner mb-3">
      <Box className="pt-2">
        <Stepper
          activeStep={studentStatusMapper[cancelledStatus ?? activeStatus]}
          alternativeLabel
        >
          {steps.map((label, index) => {
            const labelProps = {};

            if (isStatusFailed(index)) {
              labelProps.error = true;
            }

            return (
              <Step
                key={label}
                onClick={() => {
                  if (index < studentStatusMapper[activeStatus]) {
                    return onClick(
                      getKeyByValue(STUDENT_STATUSES_STEPPER_MAP, index + 1)
                    );
                  }
                }}
                style={{
                  cursor:
                    index < studentStatusMapper[activeStatus]
                      ? "pointer"
                      : "auto",
                }}
              >
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </Wrapper>
  );
};

export default StudentStepperComponent;
