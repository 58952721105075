import React from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "../../Component";

const DropZoneInput = ({
  text = "Drag and drop the file here, or click select files",
  files,
  setFiles,
  maxWidth,
  multiple,
}) => {
  const { t } = useTranslation();

  const handleDropChange = (acceptedFiles, docs, setDocs) => {
    if (multiple) {
      return setDocs([
        ...docs,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    }

    return setDocs(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  return (
    <Dropzone
      onDrop={(acceptedFiles) =>
        handleDropChange(acceptedFiles, files, setFiles)
      }
      multiple={multiple}
    >
      {({ getRootProps, getInputProps }) => (
        <section className="position-relative">
          <div
            {...getRootProps()}
            className="dropzone upload-zone small bg-white mb-2 dz-clickable"
            style={{ maxWidth: maxWidth }}
          >
            <input {...getInputProps()} />
            {files?.length < 1 || multiple ? (
              <p className="mb-3">{t(text)}</p>
            ) : null}
            {files?.map((file) => (
              <div
                key={file.name}
                className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
              >
                <div className="dz-image">
                  <img
                    src={file.preview}
                    alt="preview"
                    className="dropzone-image"
                  />
                </div>
              </div>
            ))}
          </div>
          {files?.length > 0 ? (
            <Button
              className="position-absolute b-18 r-0"
              onClick={() => setFiles([])}
              iconName={"trash-alt"}
            />
          ) : null}
        </section>
      )}
    </Dropzone>
  );
};

export default DropZoneInput;
