import React from "react";
import { Spinner } from "reactstrap";
import "./SpinnerComponent.css";

const SpinnerComponent = ({ height, width, size, margin, className }) => {
  return (
    <div
      className={`SpinnerComponent ${className}`}
      style={{ height: height ?? "60vh", width: width, margin: margin }}
    >
      <Spinner size={size} />
    </div>
  );
};

export default SpinnerComponent;
