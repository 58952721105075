import backendHttpRequest from "./backendHttpRequest";
import { config } from "../configs/repositoryConfig";
import { convertToQueryString } from "../utilities/httpUtils";
import { replaceAllStringByKeys } from "../utilities/stringUtils";

export const getEmployees = async (filteredObject) => {
  const queryString = convertToQueryString(filteredObject);
  const resourceObject = {
    ...config.backendApiSettings.resources.getEmployees,
  };
  const endpoint = resourceObject.endpoint;
  resourceObject.endpoint = `${endpoint}${
    queryString ? `?${queryString}` : ""
  }`;

  const apiUsers = await backendHttpRequest({ resourceObject });
  return apiUsers;
};

export const getEmployeeById = async (id) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.getEmployeeById,
  };
  resourceObject.endpoint = replaceAllStringByKeys(
    resourceObject.endpoint,
    [":id"],
    [id]
  );

  const apiUser = await backendHttpRequest({ resourceObject });

  return apiUser;
};

export const updateEmployeeStatus = async (status, body) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.updateEmployeeStatus,
  };

  resourceObject.endpoint = replaceAllStringByKeys(
    resourceObject.endpoint,
    [":status"],
    [status]
  );

  const updatedUser = await backendHttpRequest({
    resourceObject,
    body,
  });

  return updatedUser;
};

export const removeEmployee = async (id) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.deleteEmployee,
  };
  const endpoint = resourceObject.endpoint;
  resourceObject.endpoint = endpoint.replace(":id", id);

  await backendHttpRequest({ resourceObject });

  return id;
};
