import React from "react";
import { FILE_EXTENSIONS } from "../../utilities/constants";
import { getURLExtension } from "../../utilities/imageUtils";

import DOCIcon from "../../assets/images/doc.svg";
import PDFIcon from "../../assets/images/pdf.svg";
import FileIcon from "../../assets/images/file.svg";
import WEBPIcon from "../../assets/images/webp.svg";
import UnidefindeFileIcon from "../../assets/images/undefined-file.svg";

const ImagePlaceHolder = ({ image, imageAlt, imageName = "file" }) => {
  const renderImagePlaceHolder = (image) => {
    let imagePlaceHolder;
    const extension = getURLExtension(image);

    switch (extension) {
      case FILE_EXTENSIONS.DOCX || FILE_EXTENSIONS.DOC:
        imagePlaceHolder = DOCIcon;
        break;

      case FILE_EXTENSIONS.PDF:
        imagePlaceHolder = PDFIcon;
        break;

      case "png":
        imagePlaceHolder = image;
        break;

      case "jpg":
        imagePlaceHolder = image;
        break;

      case "jpeg":
        imagePlaceHolder = image;
        break;

      case FILE_EXTENSIONS.WEBP:
        imagePlaceHolder = WEBPIcon;
        break;

      case undefined:
        imagePlaceHolder = UnidefindeFileIcon;
        break;

      default:
        imagePlaceHolder = FileIcon;
        break;
    }

    return imagePlaceHolder;
  };
  return (
    <a href={image} target="_blank" className="d-flex flex-column h6">
      <img
        src={renderImagePlaceHolder(image)}
        alt={imageAlt}
        className="rounded"
        height={"100%"}
        width="130px"
      />
      <span className="mt-2">{imageName}</span>
    </a>
  );
};

export default ImagePlaceHolder;
